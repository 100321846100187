// import logo from './logo.svg';
import './App.css';
import Web3 from 'web3';
import { useEffect, useState } from 'react';
import "bootstrap";
import "bootstrap-icons/font/bootstrap-icons.css";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Slider from "@mui/material/Slider";
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import arcticpool from "./assets/arcticpool.png";
import southernpool from "./assets/southernpool.png";
import indianpool from "./assets/indianpool.png";
import atlanticpool from "./assets/atlanticpool.png";
import pacificpool from "./assets/pacificpool.png";
import arrow from "./assets/arrow-icon.svg";
import certik from "./assets/certik.png";
import logo from "./assets/logo.png";
import footer from "./assets/logo-full-white2x.png";
import Button from 'react-bootstrap/Button';


import { PlusOutlined,MinusOutlined } from "@ant-design/icons";

import { useMoralis, useMoralisWeb3Api } from "react-moralis";
import { Divider } from '@mui/material';
function App() {
  // var Web3 = require('web3'); 
  const Web3Api = useMoralisWeb3Api();
const node_URL = 'https://nd-921-411-021.p2pify.com/86520991c51fd40a6bd3c263c04271fc'; 
const { authenticate, isAuthenticated, isAuthenticating, user, account: walletAddress, logout, Moralis } = useMoralis();
const [matches, setMatches] = useState(
  window.matchMedia("(min-width: 768px)").matches
)
const [arcticApr, setArcticApr] = useState("");
const [arcticTotalStaked,setArcticTotalStaked] = useState("")
const [southernTotalStaked,setSouthernTotalStaked] = useState("")
const [indianTotalStaked,setIndianTotalStaked] = useState("")
const [atlanticTotalStaked,setAtlanticTotalStaked] = useState("")
const [pacificTotalStaked,setPacificTotalStaked] = useState("")
  const [southernApr, setSouthernApr] = useState("");

  const [indianApr, setIndianApr] = useState("");
  const [atlanticApr, setAtlanticApr] = useState("");
  const [pacificApr, setPacificApr] = useState("");
  const [viewMore, setViewMore] = useState(false);
  const [viewMore1, setViewMore1] = useState(false);
  const [viewMore2, setViewMore2] = useState(false);
  const [viewMore3, setViewMore3] = useState(false);
  const [viewMore4, setViewMore4] = useState(false);
  const [userStackedArc, setUserStackedArc] = useState("");
  const [userStackedSou, setUserStackedSou] = useState("");
  const [userStackedInd, setUserStackedInd] = useState("");
  const [userStackedAtl, setUserStackedAtl] = useState("");
  const [userStackedPac, setUserStackedPac] = useState("");
  const [userLockedArc, setUserLockedArc] = useState("");
  const [userLockedSou, setUserLockedSou] = useState("");
  const [userLockedInd, setUserLockedInd] = useState("");
  const [userLockedAlt, setUserLockedAlt] = useState("");
  const [userLockedPac, setUserLockedPac] = useState("");
  const [userLockedArcMax, setUserLockedArcMax] = useState(0);
  const [userLockedSouMax, setUserLockedSouMax] = useState(0);
  const [userLockedIndMax, setUserLockedIndMax] = useState(0);
  const [userLockedAltMax, setUserLockedAltMax] = useState(0);
  const [userLockedPacMax, setUserLockedPacMax] = useState(0);
  const [southernPend, setSouthernPend] = useState("");
  const [indianPend, setIndianPend] = useState("");
  const [atlanticPend, setAtlanticPend] = useState("");
  const [arcticPend, setArcticPend] = useState("");
  const [pacificPend, setPacificPend] = useState("");
  const [tokenPrice, setTokenPrice] = useState("");
  const [remainCon, setRemainCon] = useState([]);
const [remainCon2, setRemainCon2] = useState([]);
const [loadingpage, setLoadinpage] = useState(false);
const [value, setValue] = useState("");
const [valueArc, setValueArc] = useState("");
const [valueSou, setValueSou] = useState("");
const [valueInd, setValueInd] = useState("");
const [valueAtl, setValueAtl] = useState("");
const [valuePac, setValuePac] = useState("");
const [stakePool, setStakePool] = useState("");
const [open, setOpen] = useState(false);
const [openArc, setOpenArc] = useState(false);
const [openSou, setOpenSou] = useState(false);
const [openInd, setOpenInd] = useState(false);
const [openAtl, setOpenAtl] = useState(false);
const [openPac, setOpenPac] = useState(false);
const [myAlert, setMyAlert] = useState(false);
const [myAlertW, setMyAlertW] = useState(false);
const [myAlertP, setMyAlertP] = useState(false);
const [myAlertPHeading, setMyAlertPHeading] = useState('');
const [myAlertPMessage, setMyAlertPMessage] = useState('');
const [userToken, setUserToken] = useState(0);
const [coinPrice, setCoinPrice] = useState("");
const [withdrawalValue, setWithdrawalValue] = useState("");
const [userWalletMake, setUserWalletMake] = useState("");
const startBlocksAbi =  [
  {
    inputs: [],
    name: "startBlock",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
];
const lockupsAbi =  [
  {
    inputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    name: "lockups",
    outputs: [
      {
        internalType: "uint8",
        name: "stakeType",
        type: "uint8",
      },
      {
        internalType: "uint256",
        name: "duration",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "depositFee",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "withdrawFee",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "rate",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "accTokenPerShare",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "lastRewardBlock",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "totalStaked",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "totalStakedLimit",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
];
const pendingAbi=[
  {
    inputs: [
      {
        internalType: "address",
        name: "_account",
        type: "address",
      },
      {
        internalType: "uint8",
        name: "_stakeType",
        type: "uint8",
      },
    ],
    name: "pendingReward",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
];

useEffect(()=>{

  window
  .matchMedia("(min-width: 768px)")
  .addEventListener('change', e => setMatches( e.matches ));
  detectCurrentProvider()
  if (isAuthenticated) {
    // add your logic here
   

      const usertAddresss=user.get("ethAddress")
      setUserWalletMake(user.get("ethAddress"))
      // //console.log(usertAddresss)
      getUserStakes()
      fetchTokenBalances()

    
  }

  setLoadinpage(true)
  const intervalId = setInterval(() => {
    detectCurrentProvider()
    if (isAuthenticated) {
      // add your logic here
   
  
        const usertAddresss=user.get("ethAddress")
        setUserWalletMake(user.get("ethAddress"))
        // //console.log(usertAddresss)
        getUserStakes()
        fetchTokenBalances()
  
      
    }
  }, 30000);

  return () => clearInterval(intervalId);

 
 

},[isAuthenticated])
const login = async () => {
  if (typeof window.ethereum !== 'undefined') {
    if (!isAuthenticated) {

      await authenticate({signingMessage: "H2ON Securities Authentication" })
        .then(function (user) {
   
          setUserWalletMake(user.get("ethAddress"))
          getUserStakes()
          fetchTokenBalances()
          
        })
        .catch(function (error) {
      
          setMyAlertW(true)
        });

        
    }
  }
  else {
    setMyAlertW(true)
  }
  
}



const detectCurrentProvider =async()=>{
  // let provider;
  // let provider =window.etherum;
 

 
    // //console.log("no provider found")
    //console.log(window.ethereum)
    var web3 = new Web3(node_URL) 
  
   var startBlocks = new web3.eth.Contract(
      startBlocksAbi,
      "0x0660C63c1D1fc1Ee5194bBB59b4844da9C7a849F"
    )
    
    startBlocks.methods.startBlock().call(function (err, res) {
      if (err) {
        // //console.log("An error occured", err)
        return
      }
      // //console.log("The balance is: ", res)
    })
    var lockupsInfo = new web3.eth.Contract(
      lockupsAbi,
      "0x7348E415595e9396acE8Ce485c4DB85D71990076"
    )
    lockupsInfo.methods.lockups("0").call(function (err, res) {
      if (err) {
        // console.log("An error occured", err)
        return
      }
      // console.log("Arctic is: ", res.rate)
      const apr =
      ((res.rate * 28800 * 365) / res.totalStaked) * 100;
    setArcticApr(Number(apr.toFixed(2)).toLocaleString());
    // console.log("apr:", apr.toFixed(2) )
    const artictStakedTotal = (res.totalStaked / 1000000000000000000).toFixed(3)
    setArcticTotalStaked(Number(artictStakedTotal).toString())
      
    })
    lockupsInfo.methods.lockups("1").call(function (err, res) {
      if (err) {
        // console.log("An error occured", err)
        return
      }
      // console.log("Southern is: ", res)
      const apr =
      ((res.rate * 28800 * 365) / res.totalStaked) * 100;
    setSouthernApr(Number(apr.toFixed(2)).toLocaleString());
    // console.log("apr:", apr.toFixed(2) )
    const artictStakedTotal = (res.totalStaked / 1000000000000000000).toFixed(3)
    setSouthernTotalStaked(Number(artictStakedTotal).toLocaleString())
    })
    lockupsInfo.methods.lockups("2").call(function (err, res) {
      if (err) {
        // console.log("An error occured", err)
        return
      }
      // console.log("Indian is: ", res)
      const apr =
      ((res.rate * 28800 * 365) / res.totalStaked) * 100;
    setIndianApr(Number(apr.toFixed(2)).toLocaleString());
    // console.log("apr:", apr.toFixed(2) )
    const artictStakedTotal = (res.totalStaked / 1000000000000000000).toFixed(3)
    setIndianTotalStaked(Number(artictStakedTotal).toLocaleString())
    })
    lockupsInfo.methods.lockups("3").call(function (err, res) {
      if (err) {
        // console.log("An error occured", err)
        return
      }
      // console.log("Atlantic is: ", res.totalStaked)
      const apr =
      ((res.rate * 28800 * 365) / res.totalStaked) * 100;
    setAtlanticApr(Number(apr.toFixed(2)).toLocaleString());
    // console.log("apr:", apr.toFixed(2) )
    const artictStakedTotal = (res.totalStaked / 1000000000000000000).toFixed(3)
    setAtlanticTotalStaked(Number(artictStakedTotal).toLocaleString())
    })
    var lockupsInfoPacific = new web3.eth.Contract(
      lockupsAbi,
      "0x0660C63c1D1fc1Ee5194bBB59b4844da9C7a849F"
    )
    lockupsInfoPacific.methods.lockups("0").call(function (err, res) {
      if (err) {
        // console.log("An error occured", err)
        return
      }
      // console.log("Pacific is: ", res)
      const apr =
      ((res.rate * 28800 * 365) / res.totalStaked) * 100;
    setPacificApr(Number(apr.toFixed(2)).toLocaleString());
    // console.log("apr:", apr.toFixed(2) )
    const artictStakedTotal = (res.totalStaked / 1000000000000000000).toFixed(3)
    setPacificTotalStaked(Number(artictStakedTotal).toLocaleString())
    })
    fetch(
      `https://api.bscscan.com/api?module=block&action=getblockcountdown&blockno=29855440&apikey=BCZCU2KE2YM9XSYEHDPJXYYRQC5MMRJ6MX`,
      {
        method: "GET",
      },
    )
      .then((putresponse) => putresponse.json())
      .then((putresponse) => {
        // console.log(putresponse);
        const remainBlock =Number(putresponse.result.RemainingBlock).toLocaleString()
        setRemainCon(remainBlock);
        fetch(
          `https://api.bscscan.com/api?module=block&action=getblockcountdown&blockno=40367146&apikey=BCZCU2KE2YM9XSYEHDPJXYYRQC5MMRJ6MX`,
          {
            method: "GET",
          },
        )
          .then((putresponsen) => putresponsen.json())
          .then((putresponsen) => {
            // console.log(putresponsen);
            const newjhgb = Number(putresponsen.result.RemainingBlock).toLocaleString()
            setRemainCon2(newjhgb);
          })
          .catch((error) => {
            console.error(error);
          });
      })
      .catch((error) => {
        console.error(error);
      });

    if(typeof window.ethereum !== 'undefined'){
      // provider=window.etherum
      //console.log(isAuthenticated)
   
    }
  // return provider
}
const getUserStakes =async()=>{
  // setLoadinpage(false)
  const usertAddresss=user.get("ethAddress")
  await Moralis.enableWeb3();

  // //console.log(usertAddresss)
  // const web3Js = new Web3(Moralis.provider)
  // await //console.log(usertAddresss)
  // const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
 // Get ethers.js web3Provider
 fetch(
  `https://api.coingecko.com/api/v3/simple/token_price/binance-smart-chain?contract_addresses=0xE0E81c29A68bFDd7c48072fD94e7c58F1f0146C1&vs_currencies=usd`,
  {
    method: "GET",
  },
)
  .then((putresponsen) => putresponsen.json())
  .then((putresponsen) => {
    // //console.log('new coin price',putresponsen);
    // //console.log('new coins price',putresponsen['0xe0e81c29a68bfdd7c48072fd94e7c58f1f0146c1'].usd);
    setCoinPrice(putresponsen['0xe0e81c29a68bfdd7c48072fd94e7c58f1f0146c1'].usd.toFixed(3));
    setTokenPrice(putresponsen['0xe0e81c29a68bfdd7c48072fd94e7c58f1f0146c1'].usd.toFixed(3));
   
    // const newjhbjhfgb = Number(putresponsen.result.RemainingBlock).toLocaleString()
   
  })
  .catch((error) => {
    console.error(error);
  });

  // const signer = web3Provider.getSigner();
  // const usertAddresss = await walletAddress
  //console.log("getting results");
  // setUserToken(0);
  // const optionsp = {
  //   address: "0xE0E81c29A68bFDd7c48072fD94e7c58F1f0146C1",
  //   chain: "bsc",
  // };
  // const price = await Web3Api.token.getTokenPrice(optionsp);
  // setCoinPrice(price.usdPrice.toFixed(3));
  // setTokenPrice(price.usdPrice.toFixed(3));
  // //console.log("Token price",price)
  // //console.log("user Products")
  const options6 = {
    contractAddress: "0x0660C63c1D1fc1Ee5194bBB59b4844da9C7a849F",

    functionName: "pendingReward",
  
    abi: [
      {
        inputs: [
          {
            internalType: "address",
            name: "_account",
            type: "address",
          },
          {
            internalType: "uint8",
            name: "_stakeType",
            type: "uint8",
          },
        ],
        name: "pendingReward",
        outputs: [
          {
            internalType: "uint256",
            name: "",
            type: "uint256",
          },
        ],
        stateMutability: "view",
        type: "function",
      },
    ],

    params: {
      _account: usertAddresss,
      _stakeType: "0",
    },
  };
  const rewardsTo = await Moralis.executeFunction(options6);

  //console.log("done")
  const testconv = Moralis.Units.FromWei(rewardsTo);
  const options7 = {
    contractAddress: "0x7348E415595e9396acE8Ce485c4DB85D71990076",

    functionName: "pendingReward",

    abi: [
      {
        inputs: [
          {
            internalType: "address",
            name: "_account",
            type: "address",
          },
          {
            internalType: "uint8",
            name: "_stakeType",
            type: "uint8",
          },
        ],
        name: "pendingReward",
        outputs: [
          {
            internalType: "uint256",
            name: "",
            type: "uint256",
          },
        ],
        stateMutability: "view",
        type: "function",
      },
    ],

    params: {
      _account: usertAddresss,
      _stakeType: "1",
    },
  };
  const rewardsTo1 = await Moralis.executeFunction(options7);

  const options8 = {
    contractAddress: "0x7348E415595e9396acE8Ce485c4DB85D71990076",

    functionName: "pendingReward",

    abi: [
      {
        inputs: [
          {
            internalType: "address",
            name: "_account",
            type: "address",
          },
          {
            internalType: "uint8",
            name: "_stakeType",
            type: "uint8",
          },
        ],
        name: "pendingReward",
        outputs: [
          {
            internalType: "uint256",
            name: "",
            type: "uint256",
          },
        ],
        stateMutability: "view",
        type: "function",
      },
    ],

    params: {
      _account: usertAddresss,
      _stakeType: "2",
    },
  };
  const rewardsTo2 = await Moralis.executeFunction(options8);

  const options9 = {
    contractAddress: "0x7348E415595e9396acE8Ce485c4DB85D71990076",

    functionName: "pendingReward",

    abi: [
      {
        inputs: [
          {
            internalType: "address",
            name: "_account",
            type: "address",
          },
          {
            internalType: "uint8",
            name: "_stakeType",
            type: "uint8",
          },
        ],
        name: "pendingReward",
        outputs: [
          {
            internalType: "uint256",
            name: "",
            type: "uint256",
          },
        ],
        stateMutability: "view",
        type: "function",
      },
    ],

    params: {
      _account: usertAddresss,
      _stakeType: "3",
    },
  };
  const rewardsTo3 = await Moralis.executeFunction(options9);

  const options10 = {
    contractAddress: "0x7348E415595e9396acE8Ce485c4DB85D71990076",

    functionName: "pendingReward",

    abi: [
      {
        inputs: [
          {
            internalType: "address",
            name: "_account",
            type: "address",
          },
          {
            internalType: "uint8",
            name: "_stakeType",
            type: "uint8",
          },
        ],
        name: "pendingReward",
        outputs: [
          {
            internalType: "uint256",
            name: "",
            type: "uint256",
          },
        ],
        stateMutability: "view",
        type: "function",
      },
    ],

    params: {
      _account: usertAddresss,
      _stakeType: "0",
    },
  };
  const rewardsTo4 = await Moralis.executeFunction(options10);
  setArcticPend(Number((rewardsTo4._hex / 1000000000000000000).toFixed(5)).toLocaleString());

  const options11 = {
    contractAddress: "0x7348E415595e9396acE8Ce485c4DB85D71990076",

    functionName: "userInfo",

    abi: [
      {
        inputs: [
          {
            internalType: "uint8",
            name: "_stakeType",
            type: "uint8",
          },
          {
            internalType: "address",
            name: "_account",
            type: "address",
          },
        ],
        name: "userInfo",
        outputs: [
          {
            internalType: "uint256",
            name: "amount",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "available",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "locked",
            type: "uint256",
          },
        ],
        stateMutability: "view",
        type: "function",
      },
    ],

    params: {
      _account: usertAddresss,
      _stakeType: "0",
    },
  };
  const userStakedarc = await Moralis.executeFunction(options11);
  const userStakedConarc = (
    Moralis.Units.FromWei(userStakedarc.amount._hex, 3) / 1000000000000000
  ).toFixed(3);
  const userLockedConarc = (
    Moralis.Units.FromWei(userStakedarc.amount._hex, 3) / 1000000000000000
  ).toFixed(3);

  setUserStackedArc(Number(userStakedConarc).toLocaleString());
  setUserLockedArc(Number(userLockedConarc).toLocaleString());
  setUserLockedArcMax(Number(userLockedConarc));
  const options12 = {
    contractAddress: "0x7348E415595e9396acE8Ce485c4DB85D71990076",

    functionName: "userInfo",

    abi: [
      {
        inputs: [
          {
            internalType: "uint8",
            name: "_stakeType",
            type: "uint8",
          },
          {
            internalType: "address",
            name: "_account",
            type: "address",
          },
        ],
        name: "userInfo",
        outputs: [
          {
            internalType: "uint256",
            name: "amount",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "available",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "locked",
            type: "uint256",
          },
        ],
        stateMutability: "view",
        type: "function",
      },
    ],

    params: {
      _account: usertAddresss,
      _stakeType: "1",
    },
  };
  const userStakedsou = await Moralis.executeFunction(options12);
  const userStakedConsou = (
    Moralis.Units.FromWei(userStakedsou.amount._hex, 3) / 1000000000000000
  ).toFixed(3);
  const userLockedConsou = (
    Moralis.Units.FromWei(userStakedsou.amount._hex, 3) / 1000000000000000
  ).toFixed(3);
  setUserLockedSou(Number(userLockedConsou).toLocaleString());
  setUserStackedSou(Number(userStakedConsou).toLocaleString());
  setUserLockedSouMax(Number(userLockedConsou));
  const options13 = {
    contractAddress: "0x7348E415595e9396acE8Ce485c4DB85D71990076",

    functionName: "userInfo",

    abi: [
      {
        inputs: [
          {
            internalType: "uint8",
            name: "_stakeType",
            type: "uint8",
          },
          {
            internalType: "address",
            name: "_account",
            type: "address",
          },
        ],
        name: "userInfo",
        outputs: [
          {
            internalType: "uint256",
            name: "amount",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "available",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "locked",
            type: "uint256",
          },
        ],
        stateMutability: "view",
        type: "function",
      },
    ],

    params: {
      _account: usertAddresss,
      _stakeType: "2",
    },
  };
  const userStakedind = await Moralis.executeFunction(options13);
  const userStakedConind = (
    Moralis.Units.FromWei(userStakedind.amount._hex, 3) / 1000000000000000
  ).toFixed(3);

  const userLockedConind = (
    Moralis.Units.FromWei(userStakedind.amount._hex, 3) / 1000000000000000
  ).toFixed(3);
  setUserStackedInd(Number(userStakedConind).toLocaleString());
  setUserLockedInd(Number(userLockedConind).toLocaleString());
  setUserLockedIndMax(Number(userLockedConind));
  const options14 = {
    contractAddress: "0x7348E415595e9396acE8Ce485c4DB85D71990076",

    functionName: "userInfo",

    abi: [
      {
        inputs: [
          {
            internalType: "uint8",
            name: "_stakeType",
            type: "uint8",
          },
          {
            internalType: "address",
            name: "_account",
            type: "address",
          },
        ],
        name: "userInfo",
        outputs: [
          {
            internalType: "uint256",
            name: "amount",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "available",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "locked",
            type: "uint256",
          },
        ],
        stateMutability: "view",
        type: "function",
      },
    ],

    params: {
      _account: usertAddresss,
      _stakeType: "3",
    },
  };
  const userStakedatl = await Moralis.executeFunction(options14);
  const userStakedConatl = (
    Moralis.Units.FromWei(userStakedatl.amount._hex, 3) / 1000000000000000
  ).toFixed(3);
 
  const userLockedConatl = (
    Moralis.Units.FromWei(userStakedatl.amount._hex, 3) / 1000000000000000
  ).toFixed(3);
  setUserLockedAlt(Number(userLockedConatl).toLocaleString());
  setUserStackedAtl(Number(userStakedConatl).toLocaleString());
  setUserLockedAltMax(Number(userLockedConatl));

  const options15 = {
    contractAddress: "0x0660C63c1D1fc1Ee5194bBB59b4844da9C7a849F",

    functionName: "userInfo",

    abi: [
      {
        inputs: [
          {
            internalType: "uint8",
            name: "_stakeType",
            type: "uint8",
          },
          {
            internalType: "address",
            name: "_account",
            type: "address",
          },
        ],
        name: "userInfo",
        outputs: [
          {
            internalType: "uint256",
            name: "amount",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "available",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "locked",
            type: "uint256",
          },
        ],
        stateMutability: "view",
        type: "function",
      },
    ],

    params: {
      _account: usertAddresss,
      _stakeType: "0",
    },
  };
  const userStakedpac = await Moralis.executeFunction(options15);
  const userStakedConpac = (
    Moralis.Units.FromWei(userStakedpac.amount._hex, 3) / 1000000000000000
  ).toFixed(3);
  const userLockedConpac = (
    Moralis.Units.FromWei(userStakedpac.amount._hex, 3) / 1000000000000000
  ).toFixed(3);
  setUserLockedPac(Number(userLockedConpac).toLocaleString());
  setUserLockedPacMax(Number(userLockedConpac));
  setUserStackedPac(Number(userStakedConpac).toLocaleString());
  const chaneToString = (rewardsTo._hex / 1000000000000000000)
  .toFixed(5)
  .toString();
const chaneToString1 = (rewardsTo1._hex / 1000000000000000000)
  .toFixed(5)
  .toString();
const chaneToString2 = (rewardsTo2._hex / 1000000000000000000)
  .toFixed(5)
  .toString();
const chaneToString3 = (rewardsTo3._hex / 1000000000000000000)
  .toFixed(5)
  .toString();
const chaneToString4 = (rewardsTo4._hex / 1000000000000000000)
  .toFixed(5)
  .toString();
setArcticPend(Number(chaneToString4).toLocaleString());
setIndianPend(Number(chaneToString2).toLocaleString());
setSouthernPend(Number(chaneToString1).toLocaleString());
setAtlanticPend(Number(chaneToString3).toLocaleString());
setPacificPend(Number(chaneToString).toLocaleString());
  //console.log(Number(userStakedConpac).toLocaleString())
  
  

}
const handleChange = (event, newValue) => {

  const checkifNumber = Number(event)
  if(checkifNumber <= userToken){
    setValue(Number(event));
    
  }
  else if(checkifNumber > userToken){
    setValue(userToken)
  }
  else{
    return null
  }
}
const handleChangeArc = (event, newValue) => {

  const checkifNumber = Number(event)
  if(checkifNumber <= userLockedArcMax){
    setValueArc(Number(event));
    setWithdrawalValue(Number(event))
    
  }
  else if(checkifNumber > userLockedArcMax){

    setValueArc(userLockedArcMax);
    setWithdrawalValue(userLockedArcMax)
  }
  else{
    return null
  }

  
}
const handleChangeSou = (event, newValue) => {

  const checkifNumber = Number(event)
  if(checkifNumber <= userLockedSouMax){
    setValueSou(Number(event));
    setWithdrawalValue(Number(event))
    
  }
  else if(checkifNumber > userLockedSouMax){

    setValueSou(userLockedSouMax);
    setWithdrawalValue(userLockedSouMax)
  }
  else{
    return null
  }
}
const handleChangeInd = (event, newValue) => {

  const checkifNumber = Number(event)
  if(checkifNumber <= userLockedIndMax){
    setValueInd(Number(event));
    setWithdrawalValue(Number(event))
    
  }
  else if(checkifNumber > userLockedIndMax){

    setValueInd(userLockedIndMax);
    setWithdrawalValue(userLockedIndMax)
  }
  else{
    return null
  }
}
const handleChangeAtl = (event, newValue) => {

  const checkifNumber = Number(event)
  if(checkifNumber <= userLockedAltMax){
    setValueAtl(Number(event));
    setWithdrawalValue(Number(event))
    
  }
  else if(checkifNumber > userLockedAltMax){

    setValueAtl(userLockedAltMax);
    setWithdrawalValue(userLockedAltMax)
  }
  else{
    return null
  }
}
const handleChangePac = (event, newValue) => {
  const checkifNumber = Number(event)
  if(checkifNumber <= userLockedPacMax){
    setValuePac(Number(event));
    setWithdrawalValue(Number(event))
    
  }
  else if(checkifNumber > userLockedPacMax){

    setValuePac(userLockedPacMax);
    setWithdrawalValue(userLockedPacMax)
  }
  else{
    return null
  }
}
const handleOpen = async (item) => {
 
  //console.log(item);
  if (typeof window.ethereum !== 'undefined') {
    if (!isAuthenticated) {
      //console.log("user not Logged in")
      await Moralis.authenticate({ signingMessage: "H2ON Securities Authentication" })
      .then(function (user) {
        //console.log(user.get("ethAddress"));
        setUserWalletMake(user.get("ethAddress"))
        getUserStakes()
        fetchTokenBalances()
      })
      .catch(function (error) {
        //console.log(error);
      });
      

    } else {
      setStakePool(item);
      setOpen(true);
      // const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
      //console.log("user Logged in")
      // //console.log(accounts.value)
    }
  }
  else {
    setMyAlert(true)
  }
};
const handleOpenPay = async (item) => {
 

  if (typeof window.ethereum !== 'undefined') {
    if (!isAuthenticated) {
      //console.log("user not Logged in")
      await Moralis.authenticate({ signingMessage: "H2ON Securities Authentication" })
      .then(function (user) {
        //console.log(user.get("ethAddress"));
        setUserWalletMake(user.get("ethAddress"))
        getUserStakes()
        fetchTokenBalances()
      })
      .catch(function (error) {
        //console.log(error);
      });
      

    } else {
      setStakePool(item);
      if(item ==0){
        setOpenArc(true);
      }
      else if(item ==1){
        setOpenSou(true);
      }
      else if(item ==2){
        setOpenInd(true);
      }
      else if(item ==3){
        setOpenAtl(true);
      }
      else if(item ==4){
        setOpenPac(true);
      }
      // const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
      //console.log("user Logged in")
      // //console.log(accounts.value)
    }
  }
  else {
    setMyAlert(true)
  }
};

const handleCompound = async (item) => {
  await //console.log(item);
  await Moralis.enableWeb3();
  if ( !isAuthenticated) {
    alert("Please Connect to a wallet");
  } else {
    setStakePool(item);
    if (item == 4) {
      //console.log("compound 4");
      const options = {
        contractAddress: "0x0660C63c1D1fc1Ee5194bBB59b4844da9C7a849F",
        functionName: "compoundReward",
        abi: [
          {
            inputs: [
              {
                internalType: "uint8",
                name: "_stakeType",
                type: "uint8",
              },
            ],
            name: "compoundReward",
            outputs: [],
            stateMutability: "payable",
            type: "function",
          },
        ],
        params: {
          _stakeType: "0",
        },
        msgValue: Moralis.Units.ETH(0.0035),
      };
   
      try {
        const rewardsTo = await Moralis.executeFunction(options);
        //console.log(rewardsTo);
  ;
        
      } catch (error) {
        //console.log(error)
        setMyAlertPHeading(error.message)
        setMyAlertPMessage(error.data.message)
        setMyAlertP(true)
       
      }
    } else {
      //console.log("failed");
      const options = {
        contractAddress: "0x7348E415595e9396acE8Ce485c4DB85D71990076",
        functionName: "compoundReward",
        abi: [
          {
            inputs: [
              {
                internalType: "uint8",
                name: "_stakeType",
                type: "uint8",
              },
            ],
            name: "compoundReward",
            outputs: [],
            stateMutability: "payable",
            type: "function",
          },
        ],
        params: {
          _stakeType: item,
        },
        msgValue: Moralis.Units.ETH(0.0035),
      };
  
      try {
        const rewardsTo = await Moralis.executeFunction(options);
        //console.log(rewardsTo);
  ;
  
      } catch (error) {
        //console.log(error)
        setMyAlertPHeading(error.message)
        setMyAlertPMessage(error.data.message)
        setMyAlertP(true)
       
      }
    }
    // setOpenStaked(item);
    // //console.log(openStaked);
  }
};

const handleClose = () => setOpen(false);
const handleCloseArc = () => setOpenArc(false);
const handleCloseSou = () => setOpenSou(false);
const handleCloseInd = () => setOpenInd(false);
const handleCloseAtl = () => setOpenAtl(false);
const handleClosePac = () => setOpenPac(false);
const handleCloseWarning = () => setMyAlertP(false);

const handleSliderChange = (event, newValue) => {
  setValue(newValue);
};

const handleSliderChangeArc = (event, newValue) => {
  setValueArc(newValue);
  setWithdrawalValue(newValue)
};
const handleSliderChangeSou = (event, newValue) => {
  setValueSou(newValue);
  setWithdrawalValue(newValue)
};
const handleSliderChangeInd = (event, newValue) => {
  setValueInd(newValue);
  setWithdrawalValue(newValue)
};
const handleSliderChangeAtl = (event, newValue) => {
  setValueAtl(newValue);
  setWithdrawalValue(newValue)
};
const handleSliderChangePac = (event, newValue) => {
  setValuePac(newValue);
  setWithdrawalValue(newValue)
};
const handleSliderChangeCustomArc = (item) => {

  if (item == 1) {
    const customTextG = userLockedArc / 4;
    //console.log(customTextG);
    setValueArc(customTextG);
    setWithdrawalValue(customTextG)
  } else if (item == 2) {
    const customTextG = userLockedArc / 2;
    //console.log(customTextG);
    setValueArc(customTextG);
    setWithdrawalValue(customTextG)
  } else if (item == 3) {
    const customTextG = (userLockedArc / 4) * 3;
    //console.log(customTextG);
    setValueArc(customTextG);
    setWithdrawalValue(customTextG)
  } else if (item == 4) {
    const customTextG = (userLockedArc / 4) * 4;
    //console.log(customTextG);
    setValueArc(customTextG);
    setWithdrawalValue(customTextG)
  } else {
    return null;
    // setValue(newValue);
  }
};
const handleSliderChangeCustomSou = (item) => {

  if (item == 1) {
    const customTextG = userLockedSou / 4;
    //console.log(customTextG);
    setValueSou(customTextG);
    setWithdrawalValue(customTextG)
  } else if (item == 2) {
    const customTextG = userLockedSou / 2;
    //console.log(customTextG);
    setValueSou(customTextG);
    setWithdrawalValue(customTextG)
  } else if (item == 3) {
    const customTextG = (userLockedSou / 4) * 3;
    //console.log(customTextG);
    setValueSou(customTextG);
    setWithdrawalValue(customTextG)
  } else if (item == 4) {
    const customTextG = (userLockedSou / 4) * 4;
    //console.log(customTextG);
    setValueSou(customTextG);
    setWithdrawalValue(customTextG)
  } else {
    return null;
    // setValue(newValue);
  }
};
const handleSliderChangeCustomInd = (item) => {

  if (item == 1) {
    const customTextG = userLockedInd / 4;
    //console.log(customTextG);
    setValueInd(customTextG);
    setWithdrawalValue(userLockedInd)
  } else if (item == 2) {
    const customTextG = userLockedInd / 2;
    //console.log(customTextG);
    setValueInd(customTextG);
    setWithdrawalValue(customTextG)
  } else if (item == 3) {
    const customTextG = (userLockedInd / 4) * 3;
    //console.log(customTextG);
    setValueInd(customTextG);
    setWithdrawalValue(customTextG)
  } else if (item == 4) {
    const customTextG = (userLockedInd / 4) * 4;
    //console.log(customTextG);
    setValueInd(customTextG);
    setWithdrawalValue(customTextG)
  } else {
    return null;
    // setValue(newValue);
  }
};
const handleSliderChangeCustomAtl = (item) => {

  if (item == 1) {
    const customTextG = userLockedAlt / 4;
    //console.log(customTextG);
    setValueAtl(customTextG);
    setWithdrawalValue(customTextG)
  } else if (item == 2) {
    const customTextG = userLockedAlt / 2;
    //console.log(customTextG);
    setValueAtl(customTextG);
    setWithdrawalValue(customTextG)
  } else if (item == 3) {
    const customTextG = (userLockedAlt / 4) * 3;
    //console.log(customTextG);
    setValueAtl(customTextG);
    setWithdrawalValue(customTextG)
  } else if (item == 4) {
    const customTextG = (userLockedAlt / 4) * 4;
    //console.log(customTextG);
    setValueAtl(customTextG);
    setWithdrawalValue(customTextG)
  } else {
    return null;
    // setValue(newValue);
  }
};
const handleSliderChangeCustomPac = (item) => {

  if (item == 1) {
    const customTextG = userLockedPac / 4;
    //console.log(customTextG);
    setValuePac(customTextG);
    setWithdrawalValue(customTextG)
  } else if (item == 2) {
    const customTextG = userLockedPac / 2;
    //console.log(customTextG);
    setValuePac(customTextG);
    setWithdrawalValue(customTextG)
  } else if (item == 3) {
    const customTextG = (userLockedPac / 4) * 3;
    //console.log(customTextG);
    setValuePac(customTextG);
    setWithdrawalValue(customTextG)
  } else if (item == 4) {
    const customTextG = (userLockedPac / 4) * 4;
    //console.log(customTextG);
    setValuePac(customTextG);
    setWithdrawalValue(customTextG)
  } else {
    return null;
    // setValue(newValue);
  }
};
const handleSliderChangeCustom = (item) => {

  if (item == 1) {
    const customTextG = userToken / 4;
    //console.log(customTextG);
    setValue(customTextG);
  } else if (item == 2) {
    const customTextG = userToken / 2;
    //console.log(customTextG);
    setValue(customTextG);
  } else if (item == 3) {
    const customTextG = (userToken / 4) * 3;
    //console.log(customTextG);
    setValue(customTextG);
  } else if (item == 4) {
    const customTextG = (userToken / 4) * 4;
    //console.log(customTextG);
    setValue(customTextG);
  } else {
    return null;
    // setValue(newValue);
  }
};
const fetchTokenBalances = async () => {
  if (isAuthenticated) {
    const options = {
      chain: "bsc",
      address: Moralis.User.current().get("EthAddress"),
    };
    const balances = await Web3Api.account.getTokenBalances(options);

   
      const mainToken = balances.filter((items) => {
        if (items.symbol === "H2ON") {
          return items;
        } else {
          return null;
        }
      });
      //console.log('main token',mainToken.length)
      if(mainToken.length !==0){
        //console.log("H2ON Balance: " + mainToken[0].balance);
        const userCoinData = (mainToken[0].balance / 1000000000000000000).toFixed(
          5,
        );
        const userCoinDataSend = parseFloat(userCoinData)
        setUserToken(userCoinDataSend);
      }
      else{
        
        setUserToken(0)
      }
    
 
    
  } else {
    return null;
  }
};
async function handleOkPay() {
  await Moralis.enableWeb3();

  const web3Provider = await Moralis.enableWeb3(); // Get ethers.js web3Provider

  const signer = web3Provider.getSigner();

  if (stakePool == 4) {
    //console.log("nothing");
    const stakeOptions = {
      contractAddress: "0x0660C63c1D1fc1Ee5194bBB59b4844da9C7a849F",
      functionName: "withdraw",

      signer: signer,
      abi: [
        {
          "inputs": [
            {
              "internalType": "uint256",
              "name": "_amount",
              "type": "uint256"
            },
            {
              "internalType": "uint8",
              "name": "_stakeType",
              "type": "uint8"
            }
          ],
          "name": "withdraw",
          "outputs": [],
          "stateMutability": "payable",
          "type": "function"
        },
      ],

      params: {
        _amount: Moralis.Units.Token(withdrawalValue, "18"),
        _stakeType: "0",
      },
      msgValue: Moralis.Units.ETH(0.0035),
    };
    try {
      const transaction = await Moralis.executeFunction(stakeOptions);
      //console.log(transaction);
      handleClosePac();
      const intervalId = setInterval(() => {
        detectCurrentProvider()
        if (isAuthenticated) {
          // add your logic here
          //console.log('1')
          //console.log(isAuthenticated)
      
            const usertAddresss=user.get("ethAddress")
            setUserWalletMake(user.get("ethAddress"))
            // //console.log(usertAddresss)
            getUserStakes()
            fetchTokenBalances()
       
      
          
        }
      }, 5000);
    } catch (error) {
      //console.log(error)
      setMyAlertPHeading(error.message)
      setMyAlertPMessage(error.data.message)
      setMyAlertP(true)
     
    }
  } else {
    const stakeOptions = {
      contractAddress: "0x7348E415595e9396acE8Ce485c4DB85D71990076",
      functionName: "withdraw",

      signer: signer,
      abi: [
        {
          "inputs": [
            {
              "internalType": "uint256",
              "name": "_amount",
              "type": "uint256"
            },
            {
              "internalType": "uint8",
              "name": "_stakeType",
              "type": "uint8"
            }
          ],
          "name": "withdraw",
          "outputs": [],
          "stateMutability": "payable",
          "type": "function"
        },
      ],

      params: {
        _amount: Moralis.Units.Token(withdrawalValue, "18"),
        _stakeType: stakePool,
      },
      msgValue: Moralis.Units.ETH(0.0035),
    };
    
    try {
      const transaction = await Moralis.executeFunction(stakeOptions);
      //console.log(transaction);
      if(stakePool==0){
        handleCloseArc()
      }
      else if(stakePool==1){
        handleCloseSou()
      }
      else if(stakePool==2){
        handleCloseInd()
      }
      else if(stakePool==3){
        handleCloseAtl()
      }
   
    
      
    } catch (error) {
      //console.log(error)
      setMyAlertPHeading(error.message)
      setMyAlertPMessage(error.data.message)
      setMyAlertP(true)
     
    }
    

    
  }
}
async function handleOk() {
  await Moralis.enableWeb3();

  const web3Provider = await Moralis.enableWeb3(); // Get ethers.js web3Provider

  const signer = web3Provider.getSigner();

  if (stakePool == 4) {
    //console.log("nothing");
    const stakeOptions = {
      contractAddress: "0x0660C63c1D1fc1Ee5194bBB59b4844da9C7a849F",
      functionName: "deposit",

      signer: signer,
      abi: [
        {
          inputs: [
            {
              internalType: "uint256",
              name: "_amount",
              type: "uint256",
            },
            {
              internalType: "uint8",
              name: "_stakeType",
              type: "uint8",
            },
          ],
          name: "deposit",
          outputs: [],
          stateMutability: "payable",
          type: "function",
        },
      ],

      params: {
        _amount: Moralis.Units.Token(value, "18"),
        _stakeType: "0",
      },
      msgValue: Moralis.Units.ETH(0.0035),
    };
    try {
      const transaction = await Moralis.executeFunction(stakeOptions);
      //console.log(transaction);
      handleClose();
      
    } catch (error) {
      //console.log(error)
      setMyAlertPHeading(error.message)
      setMyAlertPMessage(error.data.message)
      setMyAlertP(true)
     
    }
  } else {
    const stakeOptions = {
      contractAddress: "0x7348E415595e9396acE8Ce485c4DB85D71990076",
      functionName: "deposit",

      signer: signer,
      abi: [
        {
          inputs: [
            {
              internalType: "uint256",
              name: "_amount",
              type: "uint256",
            },
            {
              internalType: "uint8",
              name: "_stakeType",
              type: "uint8",
            },
          ],
          name: "deposit",
          outputs: [],
          stateMutability: "payable",
          type: "function",
        },
      ],

      params: {
        _amount: Moralis.Units.Token(value, "18"),
        _stakeType: stakePool,
      },
      msgValue: Moralis.Units.ETH(0.0035),
    };
    try {
      const transaction = await Moralis.executeFunction(stakeOptions);
      //console.log(transaction);
      handleClose();
      
    } catch (error) {
      //console.log(error)
      setMyAlertPHeading(error.message)
      setMyAlertPMessage(error.data.message)
      setMyAlertP(true)
     
    }
  }
}
const logOut = async () => {
  await logout();
  //console.log("logged out");
}
  return (
    <>
     {/* Warning Modal */}
     {matches ?
    <Modal
    open={myAlertP}
    onClose={handleCloseWarning}
    // style={{
    //   position: "inherit",

    //   width: "100%",
    //   bgcolor: "#000000a6",
    //   border: "2px solid #000",
    //   boxShadow: 24,
    //   p: 4,
    // }}
  >
    <Box
   className='big-box-box-mod'
    >
      <div>
        <div>
          <div className="div-image-popup-1">
            <div className="popup-content">
              <div className="popup-header">
                <span
                 className='big-box-span-mod'
                >
                  {myAlertPHeading}
                </span>
                <a
                  onClick={handleCloseWarning}
                 className="big-box-a-mod"
                >
                  X
                </a>
              </div>
              <div className="popup-message">
                <div className="pop-up-box">
                <span>{myAlertPMessage}</span>

             
                </div>
              </div>
              
              <div>
              <button
                onClick={handleCloseWarning}
                class="btn btn-md btn-auto btn-grad-mod no-change"
              >
                Ok
              </button>
              </div>
              <div>
            
              </div>
            </div>
          </div>
        </div>
      </div>
    </Box>
  </Modal>:
  <Modal
  open={myAlertP}
  onClose={handleCloseWarning}
  // style={{
  //   position: "inherit",

  //   width: "100%",
  //   bgcolor: "#000000a6",
  //   border: "2px solid #000",
  //   boxShadow: 24,
  //   p: 4,
  // }}
>
  <Box

  >
    <div>
      <div>
        <div className="div-image-popup-1 small-div-mod"  >
          <div className="popup-content">
            <div className="popup-header">
              <span
                style={{
                  fontFamily: "Poppins, sans-serif",
                  color: "white",
                  fontSize: "19px",
                  fontWeight: "600",
                  paddingTop: "0%",
                }}
              >
               {myAlertPHeading}
              </span>
              <a
                onClick={handleCloseWarning}
                className="small-box-a-mod"
            
              >
                X
              </a>
            </div>
            <div className="popup-message">
              <div className="pop-up-box">

               <div style={{height:"100px"}}>
               <span >{myAlertPMessage}</span>
               </div>

             
                </div>
              </div>
            </div>
            
            <div >
            <button
              onClick={handleCloseWarning}
              class="btn btn-md btn-auto btn-grad-mod-sm2 no-change"
            
               >
              Ok
            </button>
            </div>
          
          </div>
        </div>
      </div>
      
   
  </Box>
</Modal>
    }
        {/* Arctic paymentMod */}
        {matches ?
    <Modal
    open={openArc}
    onClose={handleCloseArc}
    // style={{
    //   position: "inherit",

    //   width: "100%",
    //   bgcolor: "#000000a6",
    //   border: "2px solid #000",
    //   boxShadow: 24,
    //   p: 4,
    // }}
  >
    <Box
   className='big-box-box-mod'
    >
      <div>
        <div>
          <div className="div-image-popup-1">
            <div className="popup-content">
              <div className="popup-header">
                <span
                 className='big-box-span-mod'
                >
                  Withdraw From Pool
                </span>
                <a
                  onClick={handleCloseArc}
                 className="big-box-a-mod"
                 
                >
                  X
                </a>
              </div>
              <div className="popup-message">
                <div className="pop-up-box">
                <div className="value">{valueArc} H2ON</div>
                  <input type="text" class="input-h2o" value={valueArc} onChange={e => handleChangeArc(e.target.value)}  />
                  

             
                </div>
              </div>
              <h2
                className='big-box-h2-mod'
              >
                Balance:{" "}
                <span style={{ display: "block", float: "right" }}>
                  {userLockedArc} H2ON
                </span>
              </h2>

              <Slider
                value={typeof valueArc === "number" ? valueArc : 0}
                onChange={handleSliderChangeArc}
                // defaultValue={50}
                aria-label="Default"
                valueLabelDisplay="auto"
                color="primary"
                max={userLockedArcMax}
              />

              <div
                className='btn-grad-mod-sm-div'
              >
                <button
                  onClick={() => handleSliderChangeCustomArc("1")}
                  // className="btn-glow-new btn-hover-shine"
                  class="btn btn-md btn-auto btn-grad-mod-sm no-change"
                >
                  25 %
                </button>
                <button
                  onClick={() => handleSliderChangeCustomArc("2")}
                  class="btn btn-md btn-auto btn-grad-mod-sm no-change"
                >
                  50 %
                </button>
                <button
                  onClick={() => handleSliderChangeCustomArc("3")}
                  class="btn btn-md btn-auto btn-grad-mod-sm no-change"
                >
                  75 %
                </button>
                <button
                  onClick={() => handleSliderChangeCustomArc("4")}
                  class="btn btn-md btn-auto btn-grad-mod-sm no-change"
                >
                  MAX
                </button>
              </div>
              <div>
              <button
                onClick={handleOkPay}
                class="btn btn-md btn-auto btn-grad-mod no-change"
              >
                Confirm
              </button>
              </div>
              <div>
              <button
               class="btn btn-md btn-auto btn-grad-mod no-change"
                onClick={handleCloseArc}
              >
                Cancel
              </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Box>
  </Modal>:
  <Modal
  open={openArc}
  onClose={handleCloseArc}
  // style={{
  //   position: "inherit",

  //   width: "100%",
  //   bgcolor: "#000000a6",
  //   border: "2px solid #000",
  //   boxShadow: 24,
  //   p: 4,
  // }}
>
  <Box

  >
    <div>
      <div>
        <div className="div-image-popup-1 small-div-mod"  >
          <div className="popup-content">
            <div className="popup-header">
              <span
                style={{
                  fontFamily: "Poppins, sans-serif",
                  color: "white",
                  fontSize: "19px",
                  fontWeight: "600",
                  paddingTop: "0%",
                }}
              >
                Withdraw From Pool
              </span>
              <a
                onClick={handleCloseArc}
                className="small-box-a-mod"
              >
                X
              </a>
            </div>
            <div className="popup-message">
              <div className="pop-up-box">

                <div className="value">{valueArc} H2ON</div>
                  <input type="text" class="input-h2o" value={valueArc} onChange={e => handleChangeArc(e.target.value)}  />
                  

             
                </div>
              </div>
            </div>
            <h2
              className='small-box-h2-mod'
            >
              Balance:{" "}
              <span style={{ display: "block", float: "right" }}>
                {userLockedArc} H2ON
              </span>
            </h2>

            <Slider
              value={typeof valueArc === "number" ? valueArc : 0}
              onChange={handleSliderChangeArc}
              // defaultValue={50}
              aria-label="Default"
              valueLabelDisplay="auto"
              color="primary"
              max={userLockedArcMax}
            />

            <div
              style={{
                textAlign: "center",
                justifyContent: "space-between",
              }}
            >
              <button
                onClick={() => handleSliderChangeCustomArc("1")}
                // className="btn-glow-new btn-hover-shine"
                class="btn btn-md btn-auto btn-grad-mod-sm3 no-change"
              >
                25 %
              </button>
              <button
                onClick={() => handleSliderChangeCustomArc("2")}
                class="btn btn-md btn-auto btn-grad-mod-sm3 no-change"
              >
                50 %
              </button>
              <button
                onClick={() => handleSliderChangeCustomArc("3")}
                class="btn btn-md btn-auto btn-grad-mod-sm3 no-change"
              >
                75 %
              </button>
              <button
                onClick={() => handleSliderChangeCustomArc("4")}
                class="btn btn-md btn-auto btn-grad-mod-sm3 no-change"
              >
                MAX
              </button>
            </div>
   
            <div >
            <button
              onClick={handleOkPay}
              class="btn btn-md btn-auto btn-grad-mod-sm2 no-change"
               >
              Confirm
            </button>
            </div>
            <div>
            <button
              class="btn btn-md btn-auto btn-grad-mod-sm2 no-change"
              onClick={handleCloseArc}
            >
              Cancel
            </button>
            </div>
          </div>
        </div>
      </div>
      
   
  </Box>
</Modal>
    }
        {/* Southern paymentMod */}
        {matches ?
    <Modal
    open={openSou}
    onClose={handleCloseSou}
    // style={{
    //   position: "inherit",

    //   width: "100%",
    //   bgcolor: "#000000a6",
    //   border: "2px solid #000",
    //   boxShadow: 24,
    //   p: 4,
    // }}
  >
    <Box
   className='big-box-box-mod'
    >
      <div>
        <div>
          <div className="div-image-popup-1">
            <div className="popup-content">
              <div className="popup-header">
                <span
                 className='big-box-span-mod'
                >
                  Withdraw From Pool
                </span>
                <a
                  onClick={handleCloseSou}
                 className="big-box-a-mod"
                >
                  X
                </a>
              </div>
              <div className="popup-message">
                <div className="pop-up-box">
                <div className="value">{valueSou} H2ON</div>
                  <input type="text" class="input-h2o" value={valueSou} onChange={e => handleChangeSou(e.target.value)}  />
                  

             
                </div>
              </div>
              <h2
                className='big-box-h2-mod'
              >
                Balance:{" "}
                <span style={{ display: "block", float: "right" }}>
                  {userLockedSou} H2ON
                </span>
              </h2>

              <Slider
                value={typeof valueSou === "number" ? valueSou : 0}
                onChange={handleSliderChangeSou}
                // defaultValue={50}
                aria-label="Default"
                valueLabelDisplay="auto"
                color="primary"
                max={userLockedSouMax}
              />

              <div
                className='btn-grad-mod-sm-div'
              >
                <button
                  onClick={() => handleSliderChangeCustomSou("1")}
                  // className="btn-glow-new btn-hover-shine"
                  class="btn btn-md btn-auto btn-grad-mod-sm no-change"
                >
                  25 %
                </button>
                <button
                  onClick={() => handleSliderChangeCustomSou("2")}
                  class="btn btn-md btn-auto btn-grad-mod-sm no-change"
                >
                  50 %
                </button>
                <button
                  onClick={() => handleSliderChangeCustomSou("3")}
                  class="btn btn-md btn-auto btn-grad-mod-sm no-change"
                >
                  75 %
                </button>
                <button
                  onClick={() => handleSliderChangeCustomSou("4")}
                  class="btn btn-md btn-auto btn-grad-mod-sm no-change"
                >
                  MAX
                </button>
              </div>
              <div>
              <button
                onClick={handleOkPay}
                class="btn btn-md btn-auto btn-grad-mod no-change"
              >
                Confirm
              </button>
              </div>
              <div>
              <button
               class="btn btn-md btn-auto btn-grad-mod no-change"
                onClick={handleCloseSou}
              >
                Cancel
              </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Box>
  </Modal>:
  <Modal
  open={openSou}
  onClose={handleCloseSou}
  // style={{
  //   position: "inherit",

  //   width: "100%",
  //   bgcolor: "#000000a6",
  //   border: "2px solid #000",
  //   boxShadow: 24,
  //   p: 4,
  // }}
>
  <Box

  >
    <div>
      <div>
        <div className="div-image-popup-1 small-div-mod"  >
          <div className="popup-content">
            <div className="popup-header">
              <span
                style={{
                  fontFamily: "Poppins, sans-serif",
                  color: "white",
                  fontSize: "19px",
                  fontWeight: "600",
                  paddingTop: "0%",
                }}
              >
                Withdraw From Pool
              </span>
              <a
                onClick={handleCloseSou}
                className="small-box-a-mod"
              >
                X
              </a>
            </div>
            <div className="popup-message">
              <div className="pop-up-box">

                <div className="value">{valueSou} H2ON</div>
                  <input type="text" class="input-h2o" value={valueSou} onChange={e => handleChangeSou(e.target.value)}  />
                  

             
                </div>
              </div>
            </div>
            <h2
              className='small-box-h2-mod'
            >
              Balance:{" "}
              <span style={{ display: "block", float: "right" }}>
                {userLockedSou} H2ON
              </span>
            </h2>

            <Slider
              value={typeof valueSou === "number" ? valueSou : 0}
              onChange={handleSliderChangeSou}
              // defaultValue={50}
              aria-label="Default"
              valueLabelDisplay="auto"
              color="primary"
              max={userLockedSouMax}
            />

            <div
              style={{
                textAlign: "center",
                justifyContent: "space-between",
              }}
            >
              <button
                onClick={() => handleSliderChangeCustomSou("1")}
                // className="btn-glow-new btn-hover-shine"
                class="btn btn-md btn-auto btn-grad-mod-sm3 no-change"
              >
                25 %
              </button>
              <button
                onClick={() => handleSliderChangeCustomSou("2")}
                class="btn btn-md btn-auto btn-grad-mod-sm3 no-change"
              >
                50 %
              </button>
              <button
                onClick={() => handleSliderChangeCustomSou("3")}
                class="btn btn-md btn-auto btn-grad-mod-sm3 no-change"
              >
                75 %
              </button>
              <button
                onClick={() => handleSliderChangeCustomSou("4")}
                class="btn btn-md btn-auto btn-grad-mod-sm3 no-change"
              >
                MAX
              </button>
            </div>
   
            <div >
            <button
              onClick={handleOkPay}
              class="btn btn-md btn-auto btn-grad-mod-sm2 no-change"
               >
              Confirm
            </button>
            </div>
            <div>
            <button
              class="btn btn-md btn-auto btn-grad-mod-sm2 no-change"
              onClick={handleCloseSou}
            >
              Cancel
            </button>
            </div>
          </div>
        </div>
      </div>
      
   
  </Box>
</Modal>

    }
        {/* Indian paymentMod */}
        {matches ?
    <Modal
    open={openInd}
    onClose={handleCloseInd}
    // style={{
    //   position: "inherit",

    //   width: "100%",
    //   bgcolor: "#000000a6",
    //   border: "2px solid #000",
    //   boxShadow: 24,
    //   p: 4,
    // }}
  >
    <Box
   className='big-box-box-mod'
    >
      <div>
        <div>
          <div className="div-image-popup-1">
            <div className="popup-content">
              <div className="popup-header">
                <span
                 className='big-box-span-mod'
                >
                  Withdraw From Pool
                </span>
                <a
                  onClick={handleCloseInd}
                 className="big-box-a-mod"
                >
                  X
                </a>
              </div>
              <div className="popup-message">
                <div className="pop-up-box">
                <div className="value">{valueInd} H2ON</div>
                  <input type="text" class="input-h2o" value={valueInd} onChange={e => handleChangeInd(e.target.value)}  />
                  

             
                </div>
              </div>
              <h2
                className='big-box-h2-mod'
              >
                Balance:{" "}
                <span style={{ display: "block", float: "right" }}>
                  {userLockedInd} H2ON
                </span>
              </h2>

              <Slider
                value={typeof valueInd === "number" ? valueInd : 0}
                onChange={handleSliderChangeInd}
                // defaultValue={50}
                aria-label="Default"
                valueLabelDisplay="auto"
                color="primary"
                max={userLockedIndMax}
              />

              <div
                className='btn-grad-mod-sm-div'
              >
                <button
                  onClick={() => handleSliderChangeCustomInd("1")}
                  // className="btn-glow-new btn-hover-shine"
                  class="btn btn-md btn-auto btn-grad-mod-sm no-change"
                >
                  25 %
                </button>
                <button
                  onClick={() => handleSliderChangeCustomInd("2")}
                  class="btn btn-md btn-auto btn-grad-mod-sm no-change"
                >
                  50 %
                </button>
                <button
                  onClick={() => handleSliderChangeCustomInd("3")}
                  class="btn btn-md btn-auto btn-grad-mod-sm no-change"
                >
                  75 %
                </button>
                <button
                  onClick={() => handleSliderChangeCustomInd("4")}
                  class="btn btn-md btn-auto btn-grad-mod-sm no-change"
                >
                  MAX
                </button>
              </div>
              <div>
              <button
                onClick={handleOkPay}
                class="btn btn-md btn-auto btn-grad-mod no-change"
              >
                Confirm
              </button>
              </div>
              <div>
              <button
               class="btn btn-md btn-auto btn-grad-mod no-change"
                onClick={handleCloseInd}
              >
                Cancel
              </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Box>
  </Modal>:
  <Modal
  open={openInd}
  onClose={handleCloseInd}
  // style={{
  //   position: "inherit",

  //   width: "100%",
  //   bgcolor: "#000000a6",
  //   border: "2px solid #000",
  //   boxShadow: 24,
  //   p: 4,
  // }}
>
  <Box

  >
    <div>
      <div>
        <div className="div-image-popup-1 small-div-mod"  >
          <div className="popup-content">
            <div className="popup-header">
              <span
                style={{
                  fontFamily: "Poppins, sans-serif",
                  color: "white",
                  fontSize: "19px",
                  fontWeight: "600",
                  paddingTop: "0%",
                }}
              >
                Withdraw From Pool
              </span>
              <a
                onClick={handleCloseInd}
                className="small-box-a-mod"
              >
                X
              </a>
            </div>
            <div className="popup-message">
              <div className="pop-up-box">

                <div className="value">{valueInd} H2ON</div>
                  <input type="text" class="input-h2o" value={valueInd} onChange={e => handleChangeInd(e.target.value)}  />
                  

             
                </div>
              </div>
            </div>
            <h2
              className='small-box-h2-mod'
            >
              Balance:{" "}
              <span style={{ display: "block", float: "right" }}>
                {userLockedInd} H2ON
              </span>
            </h2>

            <Slider
              value={typeof valueInd === "number" ? valueInd : 0}
              onChange={handleSliderChangeInd}
              // defaultValue={50}
              aria-label="Default"
              valueLabelDisplay="auto"
              color="primary"
              max={userLockedInd}
            />

            <div
              style={{
                textAlign: "center",
                justifyContent: "space-between",
              }}
            >
              <button
                onClick={() => handleSliderChangeCustomInd("1")}
                // className="btn-glow-new btn-hover-shine"
                class="btn btn-md btn-auto btn-grad-mod-sm3 no-change"
              >
                25 %
              </button>
              <button
                onClick={() => handleSliderChangeCustomInd("2")}
                class="btn btn-md btn-auto btn-grad-mod-sm3 no-change"
              >
                50 %
              </button>
              <button
                onClick={() => handleSliderChangeCustomInd("3")}
                class="btn btn-md btn-auto btn-grad-mod-sm3 no-change"
              >
                75 %
              </button>
              <button
                onClick={() => handleSliderChangeCustomInd("4")}
                class="btn btn-md btn-auto btn-grad-mod-sm3 no-change"
              >
                MAX
              </button>
            </div>
   
            <div >
            <button
              onClick={handleOkPay}
              class="btn btn-md btn-auto btn-grad-mod-sm2 no-change"
               >
              Confirm
            </button>
            </div>
            <div>
            <button
              class="btn btn-md btn-auto btn-grad-mod-sm2 no-change"
              onClick={handleCloseInd}
            >
              Cancel
            </button>
            </div>
          </div>
        </div>
      </div>
      
   
  </Box>
</Modal>
    }
        {/* Atlantic paymentMod */}
        {matches ?
    <Modal
    open={openAtl}
    onClose={handleCloseAtl}
    // style={{
    //   position: "inherit",

    //   width: "100%",
    //   bgcolor: "#000000a6",
    //   border: "2px solid #000",
    //   boxShadow: 24,
    //   p: 4,
    // }}
  >
    <Box
   className='big-box-box-mod'
    >
      <div>
        <div>
          <div className="div-image-popup-1">
            <div className="popup-content">
              <div className="popup-header">
                <span
                 className='big-box-span-mod'
                >
                  Withdraw From Pool
                </span>
                <a
                  onClick={handleCloseAtl}
                 className="big-box-a-mod"
                >
                  X
                </a>
              </div>
              <div className="popup-message">
                <div className="pop-up-box">
                <div className="value">{valueAtl} H2ON</div>
                  <input type="text" class="input-h2o" value={valueAtl} onChange={e => handleChangeAtl(e.target.value)}  />
                  

             
                </div>
              </div>
              <h2
                className='big-box-h2-mod'
              >
                Balance:{" "}
                <span style={{ display: "block", float: "right" }}>
                  {userLockedAlt} H2ON
                </span>
              </h2>

              <Slider
                value={typeof valueAtl === "number" ? valueAtl : 0}
                onChange={handleSliderChangeAtl}
                // defaultValue={50}
                aria-label="Default"
                valueLabelDisplay="auto"
                color="primary"
                max={userLockedAltMax}
              />

              <div
                className='btn-grad-mod-sm-div'
              >
                <button
                  onClick={() => handleSliderChangeCustomAtl("1")}
                  // className="btn-glow-new btn-hover-shine"
                  class="btn btn-md btn-auto btn-grad-mod-sm no-change"
                >
                  25 %
                </button>
                <button
                  onClick={() => handleSliderChangeCustomAtl("2")}
                  class="btn btn-md btn-auto btn-grad-mod-sm no-change"
                >
                  50 %
                </button>
                <button
                  onClick={() => handleSliderChangeCustomAtl("3")}
                  class="btn btn-md btn-auto btn-grad-mod-sm no-change"
                >
                  75 %
                </button>
                <button
                  onClick={() => handleSliderChangeCustomAtl("4")}
                  class="btn btn-md btn-auto btn-grad-mod-sm no-change"
                >
                  MAX
                </button>
              </div>
              <div>
              <button
                onClick={handleOkPay}
                class="btn btn-md btn-auto btn-grad-mod no-change"
              >
                Confirm
              </button>
              </div>
              <div>
              <button
               class="btn btn-md btn-auto btn-grad-mod no-change"
                onClick={handleCloseAtl}
              >
                Cancel
              </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Box>
  </Modal>:
  <Modal
  open={openAtl}
  onClose={handleCloseAtl}
  // style={{
  //   position: "inherit",

  //   width: "100%",
  //   bgcolor: "#000000a6",
  //   border: "2px solid #000",
  //   boxShadow: 24,
  //   p: 4,
  // }}
>
  <Box

  >
    <div>
      <div>
        <div className="div-image-popup-1 small-div-mod"  >
          <div className="popup-content">
            <div className="popup-header">
              <span
                style={{
                  fontFamily: "Poppins, sans-serif",
                  color: "white",
                  fontSize: "19px",
                  fontWeight: "600",
                  paddingTop: "0%",
                }}
              >
                Withdraw From Pool
              </span>
              <a
                onClick={handleCloseAtl}
                className="small-box-a-mod"
              >
                X
              </a>
            </div>
            <div className="popup-message">
              <div className="pop-up-box">

                <div className="value">{valueAtl} H2ON</div>
                  <input type="text" class="input-h2o" value={valueAtl} onChange={e => handleChangeAtl(e.target.value)}  />
                  

             
                </div>
              </div>
            </div>
            <h2
              className='small-box-h2-mod'
            >
              Balance:{" "}
              <span style={{ display: "block", float: "right" }}>
                {userLockedAlt} H2ON
              </span>
            </h2>

            <Slider
              value={typeof valueAtl === "number" ? valueAtl : 0}
              onChange={handleSliderChangeAtl}
              // defaultValue={50}
              aria-label="Default"
              valueLabelDisplay="auto"
              color="primary"
              max={userLockedAltMax}
            />

            <div
              style={{
                textAlign: "center",
                justifyContent: "space-between",
              }}
            >
              <button
                onClick={() => handleSliderChangeCustomAtl("1")}
                // className="btn-glow-new btn-hover-shine"
                class="btn btn-md btn-auto btn-grad-mod-sm3 no-change"
              >
                25 %
              </button>
              <button
                onClick={() => handleSliderChangeCustomAtl("2")}
                class="btn btn-md btn-auto btn-grad-mod-sm3 no-change"
              >
                50 %
              </button>
              <button
                onClick={() => handleSliderChangeCustomAtl("3")}
                class="btn btn-md btn-auto btn-grad-mod-sm3 no-change"
              >
                75 %
              </button>
              <button
                onClick={() => handleSliderChangeCustomAtl("4")}
                class="btn btn-md btn-auto btn-grad-mod-sm3 no-change"
              >
                MAX
              </button>
            </div>
   
            <div >
            <button
              onClick={handleOkPay}
              class="btn btn-md btn-auto btn-grad-mod-sm2 no-change"
               >
              Confirm
            </button>
            </div>
            <div>
            <button
              class="btn btn-md btn-auto btn-grad-mod-sm2 no-change"
              onClick={handleCloseAtl}
            >
              Cancel
            </button>
            </div>
          </div>
        </div>
      </div>
      
   
  </Box>
</Modal>
    }
        {/* Pacific paymentMod */}
        {matches ?
    <Modal
    open={openPac}
    onClose={handleClosePac}
    // style={{
    //   position: "inherit",

    //   width: "100%",
    //   bgcolor: "#000000a6",
    //   border: "2px solid #000",
    //   boxShadow: 24,
    //   p: 4,
    // }}
  >
    <Box
   className='big-box-box-mod'
    >
      <div>
        <div>
          <div className="div-image-popup-1">
            <div className="popup-content">
              <div className="popup-header">
                <span
                 className='big-box-span-mod'
                >
                  Withdraw From Pool
                </span>
                <a
                  onClick={handleClosePac}
                 className="big-box-a-mod"
                >
                  X
                </a>
              </div>
              <div className="popup-message">
                <div className="pop-up-box">
                <div className="value">{valuePac} H2ON</div>
                  <input type="text" class="input-h2o" value={valuePac} onChange={e => handleChangePac(e.target.value)}  />
                  

             
                </div>
              </div>
              <h2
                className='big-box-h2-mod'
              >
                Balance:{" "}
                <span style={{ display: "block", float: "right" }}>
                  {userLockedPac} H2ON
                </span>
              </h2>

              <Slider
                value={typeof valuePac === "number" ? valuePac : 0}
                onChange={handleSliderChangePac}
                // defaultValue={50}
                aria-label="Default"
                valueLabelDisplay="auto"
                color="primary"
                max={userLockedPacMax}
              />

              <div
                className='btn-grad-mod-sm-div'
              >
                <button
                  onClick={() => handleSliderChangeCustomPac("1")}
                  // className="btn-glow-new btn-hover-shine"
                  class="btn btn-md btn-auto btn-grad-mod-sm no-change"
                >
                  25 %
                </button>
                <button
                  onClick={() => handleSliderChangeCustomPac("2")}
                  class="btn btn-md btn-auto btn-grad-mod-sm no-change"
                >
                  50 %
                </button>
                <button
                  onClick={() => handleSliderChangeCustomPac("3")}
                  class="btn btn-md btn-auto btn-grad-mod-sm no-change"
                >
                  75 %
                </button>
                <button
                  onClick={() => handleSliderChangeCustomPac("4")}
                  class="btn btn-md btn-auto btn-grad-mod-sm no-change"
                >
                  MAX
                </button>
              </div>
              <div>
              <button
                onClick={handleOkPay}
                class="btn btn-md btn-auto btn-grad-mod no-change"
              >
                Confirm
              </button>
              </div>
              <div>
              <button
               class="btn btn-md btn-auto btn-grad-mod no-change"
                onClick={handleClosePac}
              >
                Cancel
              </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Box>
  </Modal>:
  <Modal
  open={openPac}
  onClose={handleClosePac}
  // style={{
  //   position: "inherit",

  //   width: "100%",
  //   bgcolor: "#000000a6",
  //   border: "2px solid #000",
  //   boxShadow: 24,
  //   p: 4,
  // }}
>
  <Box

  >
    <div>
      <div>
        <div className="div-image-popup-1 small-div-mod"  >
          <div className="popup-content">
            <div className="popup-header">
              <span
                style={{
                  fontFamily: "Poppins, sans-serif",
                  color: "white",
                  fontSize: "19px",
                  fontWeight: "600",
                  paddingTop: "0%",
                }}
              >
                Withdraw From Pool
              </span>
              <a
                onClick={handleClosePac}
                className="small-box-a-mod"
              >
                X
              </a>
            </div>
            <div className="popup-message">
              <div className="pop-up-box">

                <div className="value">{valuePac} H2ON</div>
                  <input type="text" class="input-h2o" value={valuePac} onChange={e => handleChangePac(e.target.value)}  />
                  

             
                </div>
              </div>
            </div>
            <h2
              className='small-box-h2-mod'
            >
              Balance:{" "}
              <span style={{ display: "block", float: "right" }}>
                {userLockedPac} H2ON
              </span>
            </h2>

            <Slider
              value={typeof valuePac === "number" ? valuePac : 0}
              onChange={handleSliderChangePac}
              // defaultValue={50}
              aria-label="Default"
              valueLabelDisplay="auto"
              color="primary"
              max={userLockedPac}
            />

            <div
              style={{
                textAlign: "center",
                justifyContent: "space-between",
              }}
            >
              <button
                onClick={() => handleSliderChangeCustomPac("1")}
                // className="btn-glow-new btn-hover-shine"
                class="btn btn-md btn-auto btn-grad-mod-sm3 no-change"
              >
                25 %
              </button>
              <button
                onClick={() => handleSliderChangeCustomPac("2")}
                class="btn btn-md btn-auto btn-grad-mod-sm3 no-change"
              >
                50 %
              </button>
              <button
                onClick={() => handleSliderChangeCustomPac("3")}
                class="btn btn-md btn-auto btn-grad-mod-sm3 no-change"
              >
                75 %
              </button>
              <button
                onClick={() => handleSliderChangeCustomPac("4")}
                class="btn btn-md btn-auto btn-grad-mod-sm3 no-change"
              >
                MAX
              </button>
            </div>
   
            <div >
            <button
              onClick={handleOkPay}
              class="btn btn-md btn-auto btn-grad-mod-sm2 no-change"
               >
              Confirm
            </button>
            </div>
            <div>
            <button
              class="btn btn-md btn-auto btn-grad-mod-sm2 no-change"
              onClick={handleClosePac}
            >
              Cancel
            </button>
            </div>
          </div>
        </div>
      </div>
      
   
  </Box>
</Modal>
    }
    {/* Old Modal */}
     
      {/* Deposits Mod */}
    {matches ?
    <Modal
    open={open}
    onClose={handleClose}
    // style={{
    //   position: "inherit",

    //   width: "100%",
    //   bgcolor: "#000000a6",
    //   border: "2px solid #000",
    //   boxShadow: 24,
    //   p: 4,
    // }}
  >
    <Box
   className='big-box-box-mod'
    >
      <div>
        <div>
          <div className="div-image-popup-1">
            <div className="popup-content">
              <div className="popup-header">
                <span
                 className='big-box-span-mod'
                >
                  Stake in Pool
                </span>
                <a
                  onClick={handleClose}
                 className="big-box-a-mod"
                >
                  X
                </a>
              </div>
              <div className="popup-message">
                <div className="pop-up-box">
                <div className="value">{value} H2ON</div>
                  <input type="text" class="input-h2o" value={value} onChange={e => handleChange(e.target.value)}  />
                  

             
                </div>
              </div>
              <h2
                className='big-box-h2-mod'
              >
                Balance:{" "}
                <span style={{ display: "block", float: "right" }}>
                  {userToken} H2ON
                </span>
              </h2>

              <Slider
                value={typeof value === "number" ? value : 0}
                onChange={handleSliderChange}
                // defaultValue={50}
                aria-label="Default"
                valueLabelDisplay="auto"
                color="primary"
                max={userToken}
              />

              <div
                className='btn-grad-mod-sm-div'
              >
                <button
                  onClick={() => handleSliderChangeCustom("1")}
                  // className="btn-glow-new btn-hover-shine"
                  class="btn btn-md btn-auto btn-grad-mod-sm no-change"
                >
                  25 %
                </button>
                <button
                  onClick={() => handleSliderChangeCustom("2")}
                  class="btn btn-md btn-auto btn-grad-mod-sm no-change"
                >
                  50 %
                </button>
                <button
                  onClick={() => handleSliderChangeCustom("3")}
                  class="btn btn-md btn-auto btn-grad-mod-sm no-change"
                >
                  75 %
                </button>
                <button
                  onClick={() => handleSliderChangeCustom("4")}
                  class="btn btn-md btn-auto btn-grad-mod-sm no-change"
                >
                  MAX
                </button>
              </div>
              <div>
              <button
                onClick={handleOk}
                class="btn btn-md btn-auto btn-grad-mod no-change"
              >
                Confirm
              </button>
              </div>
              <div>
              <button
               class="btn btn-md btn-auto btn-grad-mod no-change"
                onClick={handleClose}
              >
                Cancel
              </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Box>
  </Modal>:
  <Modal
  open={open}
  onClose={handleClose}
  // style={{
  //   position: "inherit",

  //   width: "100%",
  //   bgcolor: "#000000a6",
  //   border: "2px solid #000",
  //   boxShadow: 24,
  //   p: 4,
  // }}
>
  <Box
   
  >
    <div>
      <div>
        <div className="div-image-popup-1 small-div-mod"  >
          <div className="popup-content">
            <div className="popup-header">
              <span
                style={{
                  fontFamily: "Poppins, sans-serif",
                  color: "white",
                  fontSize: "19px",
                  fontWeight: "600",
                  paddingTop: "0%",
                }}
              >
                Stake in Pool
              </span>
              <a
                onClick={handleClose}
                className="small-box-a-mod"
              >
                X
              </a>
            </div>
            <div className="popup-message">
              <div className="pop-up-box">
              <div className="value">{value} H2ON</div>
                  <input type="text" class="input-h2o" value={value} onChange={e => handleChange(e.target.value)}  />
                  

             
                </div>
            </div>
            <h2
              className='small-box-h2-mod'
            >
              Balance:{" "}
              <span style={{ display: "block", float: "right" }}>
                {userToken} H2ON
              </span>
            </h2>

            <Slider
              value={typeof value === "number" ? value : 0}
              onChange={handleSliderChange}
              // defaultValue={50}
              aria-label="Default"
              valueLabelDisplay="auto"
              color="primary"
              max={userToken}
            />

            <div
              style={{
                textAlign: "center",
                justifyContent: "space-between",
              }}
            >
              <button
                onClick={() => handleSliderChangeCustom("1")}
                // className="btn-glow-new btn-hover-shine"
                class="btn btn-md btn-auto btn-grad-mod-sm3 no-change"
              >
                25 %
              </button>
              <button
                onClick={() => handleSliderChangeCustom("2")}
                class="btn btn-md btn-auto btn-grad-mod-sm3 no-change"
              >
                50 %
              </button>
              <button
                onClick={() => handleSliderChangeCustom("3")}
                class="btn btn-md btn-auto btn-grad-mod-sm3 no-change"
              >
                75 %
              </button>
              <button
                onClick={() => handleSliderChangeCustom("4")}
                class="btn btn-md btn-auto btn-grad-mod-sm3 no-change"
              >
                MAX
              </button>
            </div>
   
            <div >
            <button
              onClick={handleOk}
              class="btn btn-md btn-auto btn-grad-mod-sm2 no-change"
               >
              Confirm
            </button>
            </div>
            <div>
            <button
              class="btn btn-md btn-auto btn-grad-mod-sm2 no-change"
              onClick={handleClose}
            >
              Cancel
            </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Box>
</Modal>
    }
      {loadingpage ? 
       <div>
       {matches ? 
       //big screen
       <div className='App'>
       <div className='main-header' >
  
        {isAuthenticated ?
        <div>
                <img src={logo} style={{width:'10%',marginRight:"5px", float:'left', resizeMode:"contain"}}/>
                <div style={{float:'right'}}>
                  <button class="btn btn-md btn-auto btn-grad no-change"  onClick={() =>
                                        window.open(
                                          "https://www.h2o-securities.com/",
                                          "_blank",
                                        )
                                      }>Home</button>
                      <Button variant="btn btn-grad content-popup">{userWalletMake.substring(0, 6)}...{userWalletMake.substring(userWalletMake.length - 4)}</Button>
                      <button class="btn btn-md btn-auto btn-grad no-change" onClick={()=>logOut()}>Disconnect Wallet</button>
                      </div>
                      
                    </div>:
                <div>
                  <img src={logo} style={{width:'10%',marginRight:"5px", float:'left', resizeMode:"contain"}}/>
                  <div style={{float:'right'}}>
                    <button class="btn btn-md btn-auto btn-grad no-change"  onClick={() =>
                                          window.open(
                                            "https://www.h2o-securities.com/",
                                            "_blank",
                                          )
                                        }>Home</button>
                    <button class="btn btn-md btn-auto btn-grad no-change" onClick={()=>login()} >Connect Wallet</button>
                  </div>
        
          </div>
        }
    
       </div>
       {myAlertW ?  
          <div style={{marginTop:"5px"}}>
           <Stack sx={{ width: '20%',marginLeft:"40%", marginRight:"40%" }} spacing={2}>
             
             <Alert severity="info" 
             action={
               <IconButton
                 aria-label="close"
                 color="inherit"
                 size="small"
                 onClick={() => {
                   setMyAlertW(false);
                 }}
               >
                 <CloseIcon fontSize="inherit" />
               </IconButton>
             }
             sx={{ mb: 2 }}
             >No Crypto Wallet found</Alert>
            
           </Stack>
          </div>: null
          }
        <h1 style={{ fontFamily: "Poppins, sans-serif", color: "white" }}>
          H2ON Staking Pools
        </h1>
    
      {/* Arctic Pool */}
        <div className="ac-body"   >
          
            <div className="accordian-item">
                <div className="accordian-item-header" onClick={()=>setViewMore(!viewMore)}>
                  <div class="setrow">
                    <div class="col-md-3">
                      <div class="setrow">
                        <div class='col-md-6'>
                          <img src={arcticpool} className="icon-pool" />
                        </div>
                        <div class='col-md-6'>
                          <div className="afterimage">
                            <h2 className="accord-title">Arctic Pool</h2>
                            {/* <h2 className="accord-sub-title">Stake H2ON</h2> */}
                            <h2 className="accord-title-span-sm">Lock: 30 Days</h2>
                          </div>
                        </div>
                      </div>
                    </div>
                    {arcticPend === null || arcticPend==="" || arcticPend===0 || arcticPend==="0.00000" ? 
                     <div className="col-md-2">
                     <h2 className="accord-title-span">H2ON Earned</h2>
                     <h2 className="accord-sub-title">
                       0.00000 H2ON
                     </h2>
                   </div>
                    : (
                      <div className="col-md-2">
                        <h2 className="accord-title-span">H2ON Earned</h2>
                        <h2 className="accord-sub-title">
                          {arcticPend} H2ON
                        </h2>
                      </div>
                    )}
                    <div className="col-md-2">
                      <h2 className="accord-title-span">APR</h2>
                      <h2 className="accord-sub-title">{arcticApr}%</h2>
                    </div>
                    <div className="col-sm-2">
                      <h2 className="accord-title-span">Total Staked</h2>
                      <h2 className="accord-sub-title">
                        {arcticTotalStaked}{" "}
                        H2ON
                      </h2>
                    </div>
                    <div className="col-md-2">
                      <h2 className="accord-title-span">Ends In</h2>
                      <h2 className="accord-sub-title">{remainCon} Blocks</h2>
                    </div>
                    <div class="btn btn-md btn-auto btn-grad-head no-change" >
                      <PlusOutlined
                        style={{
                          color: "white",
                         
                          fontSize: "20px",
                          justifySelf:'center'
                        }}
                        onClick={()=>setViewMore(!viewMore)}
                      />
                   
                      {/* <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="white" className="bi bi-plus-lg" viewBox="0 0 16 16" onClick={click} >
                        <path fill-rule="evenodd" d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2Z"/>
                      </svg> */}
                    </div>
                  </div>
                </div>
                <div
                  className="accordian-item-body"
                  style={{ maxHeight: viewMore ? 300 : 0 }}
                >
                  <div className="accordian-item-body-content">
                    <div className="setrow">
                      <div className='col-md-3'>
                        <div>
                          <h2 className="accord-title-span-block">Starts In</h2>
                        </div>
                        <h2 className="accord-sub-title-block">
                        19,343,440 Blocks
                        </h2>
                        <div>
                        <button className="accord-content-sub-title-button"
                          onClick={() =>
                            window.open(
                              "https://bscscan.com/address/0xe0e81c29a68bfdd7c48072fd94e7c58f1f0146c1",
                              "_blank",
                            )
                          } 
                        >
                        {/* <h2
                          className="accord-content-sub-title"
                        
                        > */}
                          See Token Info{" "}
                          {/* <i className=" data-arrow fa fa-long-arrow-right"></i> */}
                          {/* <img src={arrow} style={{width:'5%'}}                     onClick={() =>
                            window.open(
                              "https://bscscan.com/address/0xe0e81c29a68bfdd7c48072fd94e7c58f1f0146c1",
                              "_blank",
                            )
                          }/> */}
                        {/* </h2> */}
                        </button>
                        </div>
                        <button
                          className="accord-content-mini-sub-title-button"
                          onClick={() =>
                            window.open(
                              "https://www.h2o-securities.com/",
                              "_blank",
                            )
                          }
 
                        >
                          View Project Site{" "}
                       
                        </button>
    
                        <br />
                        <br />
                        <div className="space">
                          <img
                            src={certik}
                            alt=""
                            style={{ height: "30px", resizeMode: "contain" }}
                          />
                        </div>
                      </div>
    
                      <div className="col-md-3">
                        {userStackedArc === null ||userStackedArc === "" ? null : (
                          <div className='my-coll-test'>
                            <div>
                              <h2 className="accord-content-second-title">
                                Staked
                              </h2>
                              <h2 className="accord-second-sub-title">
                                {userStackedArc} H2ON
                              </h2>
                              <span style={{ color: "white", fontSize: "10px" }}>
                                {(Number(userStackedArc) * tokenPrice).toFixed(3)} USD
                              </span>
                              <h2 className="accord-content-second-title">
                                Locked
                              </h2>
                              <h2 className="accord-second-sub-title">
                                {userLockedArc} H2ON
                              </h2>
                            </div>
     
                            
                          </div>
                        )}
                      </div>
                   
                      <div className="col-md-3">
                        {userStackedArc === null ||userStackedArc === "" ? null : (
                          <div className='my-coll-test'>
                         
     
                            <div>
                              <h2 className="accord-content-second-title">
                                Earned
                              </h2>
                              <h2 className="accord-second-sub-title">
                                {arcticPend} H2ON
                              </h2>
                              <span style={{ color: "white", fontSize: "10px" }}>
                                {(arcticPend * tokenPrice).toFixed(3)} USD
                              </span>
                              <br />
                              <button
                                class="btn btn-md btn-auto btn-grad no-change"
                                onClick={() => handleCompound("0")}
                              >
                                Compound 
                              </button>
                           
                            </div>
                          </div>
                        )}
                      </div>
                      <div className="col-md-3">
                        <span className="accord-content-second-title">
                          <span className="span-blue"> Lock</span> Duration :
                        </span>
                        <h2 className="accord-second-sub-title">30 Days</h2>
    
                        <span className="accord-content-second-title">
                          <span className="span-blue"> Deposit</span> Fee :
                        </span>
                        <h2 className="accord-second-sub-title"> 2.00 %</h2>
    
                        <span className="accord-content-second-title">
                          <span className="span-blue"> Withdraw</span> Fee :
                        </span>
                        <h2 className="accord-second-sub-title">0.00 %</h2>
                        <div className="box-glow">
                          {/* <h2 className="accord-glow-title">START STAKING</h2> */}
                          {userStackedArc === null || userStackedArc === 0 || userStackedArc ==="" || userStackedArc ==="0.00000" || userStackedArc ==="0.0000"|| userStackedArc ==="0.000" ? (
                            <div>
                                                 <a
                              onClick={() => handleOpen("0")}
                              // value="0"
                              // onPress={handleOpen(value)}
                              class="btn btn-md btn-auto btn-grad no-change"
                            >
                              Stake
                            </a>
                             {myAlert ?  
                             <div style={{marginTop:"5px"}}>
                              <Stack sx={{ width: '100%' }} spacing={2}>
                                
                                <Alert severity="info" 
                                action={
                                  <IconButton
                                    aria-label="close"
                                    color="inherit"
                                    size="small"
                                    onClick={() => {
                                      setMyAlert(false);
                                    }}
                                  >
                                    <CloseIcon fontSize="inherit" />
                                  </IconButton>
                                }
                                sx={{ mb: 2 }}
                                >No Crypto Wallet found</Alert>
                               
                              </Stack>
                             </div>: null
                             }
                            </div>
                          ) : (
                            <div style={{flexDirection:'row'}}>
                              <div class="btn btn-md btn-auto btn-grad no-change">
                                        <MinusOutlined
                                  style={{
                                    color: "white",
                                    
                                    fontSize: "20px",
                                  }}
                                  onClick={() => handleOpenPay("0")}
                             
                                />
                              </div>
                             
                              <div class="btn btn-md btn-auto btn-grad no-change">
                                <PlusOutlined
                                  style={{
                                    color: "white",
                                    
                                    fontSize: "20px",
                                  }}
                                  onClick={() => handleOpen("0")}
                                />
                              </div>
                       
                            </div>
                          )}
                        </div>
                      </div>
    
                   
                    </div>
                  </div>
                </div>
              </div>
        
            </div>
             {/* Southern Pool */}
        <div className="ac-body" >
             
              <div className="accordian-item">
                <div className="accordian-item-header" onClick={()=>setViewMore1(!viewMore1)}>
                  <div className="setrow">
                    <div className="col-md-3">
                      <div class="setrow">
                        <div className="col-md-6">
                          <img src={southernpool} className="icon-pool" />
                        </div>
                        <div className="col-md-6">
                          <div className="afterimage">
                            <h2 className="accord-title">Southern Pool</h2>
                            <h2 className="accord-title-span-sm">Lock: 90 Days</h2>
                          </div>
                        </div>
                      </div>
                    </div>
                    {southernPend === null || southernPend==="" || southernPend===0 || southernPend==="0.0"|| southernPend==="0.00"|| southernPend==="0.000"|| southernPend==="0.0000"|| southernPend==="0.00000" ? 
                     <div className="col-md-2">
                     <h2 className="accord-title-span">H2ON Earned</h2>
                     <h2 className="accord-sub-title">
                       0.00000 H2ON
                     </h2>
                   </div>
                    : (
                      <div className="col-md-2">
                        <h2 className="accord-title-span">H2ON Earned</h2>
                        <h2 className="accord-sub-title">
                          {southernPend} H2ON
                        </h2>
                      </div>
                    )}
                    <div className="col-md-2">
                      <h2 className="accord-title-span">APR</h2>
                      <h2 className="accord-sub-title">{southernApr}%</h2>
                    </div>
                    <div className="col-md-2">
                      <h2 className="accord-title-span">Total Staked</h2>
                      <h2 className="accord-sub-title">
                        {southernTotalStaked}{" "}
                        H2ON
                      </h2>
                    </div>
                    <div className="col-md-2">
                      <h2 className="accord-title-span">Ends In</h2>
                      <h2 className="accord-sub-title">{remainCon} Blocks</h2>
                    </div>
                    <div class="btn btn-md btn-auto btn-grad-head no-change">
                    <PlusOutlined
                        style={{
                          color: "white",
                          
                          fontSize: "20px",
                        }}
                        onClick={()=>setViewMore1(!viewMore1)}
                      />
                     
                      {/* <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="white" className="bi bi-plus-lg" viewBox="0 0 16 16" onClick={() => setViewMore1(!viewMore1)}>
                        <path fill-rule="evenodd" d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2Z"/>
                      </svg> */}
                    </div>
                  </div>
                </div>
                <div
                  className="accordian-item-body"
                  style={{ maxHeight: viewMore1 ? 300 : 0 }}
                >
                  <div className="accordian-item-body-content">
                    <div className="setrow">
                      <div className="col-md-3">
                        <div >
                            <h2 className="accord-title-span-block">Starts In</h2>
                        </div>
                        <h2 className="accord-sub-title-block">
                        19,343,440 Blocks
                        </h2>
                        <div>
                        <button className="accord-content-sub-title-button"
                          onClick={() =>
                            window.open(
                              "https://bscscan.com/address/0xe0e81c29a68bfdd7c48072fd94e7c58f1f0146c1",
                              "_blank",
                            )
                          } 
                        >
                        {/* <h2
                          className="accord-content-sub-title"
                        
                        > */}
                          See Token Info{" "}
                          {/* <i className=" data-arrow fa fa-long-arrow-right"></i> */}
                          {/* <img src={arrow} style={{width:'5%'}}                     onClick={() =>
                            window.open(
                              "https://bscscan.com/address/0xe0e81c29a68bfdd7c48072fd94e7c58f1f0146c1",
                              "_blank",
                            )
                          }/> */}
                        {/* </h2> */}
                        </button>
                        </div>
                        <button
                          className="accord-content-mini-sub-title-button"
                          onClick={() =>
                            window.open(
                              "https://www.h2o-securities.com/",
                              "_blank",
                            )
                          }
 
                        >
                          View Project Site{" "}
                       
                        </button>
    
    
                        <br />
                        <br />
                        <div className="space">
                          <img
                            src={certik}
                            alt=""
                            style={{ height: "30px", resizeMode: "contain" }}
                          />
                        </div>
                      </div>
    
                      <div className="col-md-3">
                        {userStackedSou === null ||userStackedSou === "" ? null : (
                          <div className='my-coll-test'>
                            <div>
                            <h2 className="accord-content-second-title">
                              Staked
                            </h2>
                            <h2 className="accord-second-sub-title">
                              {userStackedSou} H2ON
                            </h2>
                            <span style={{ color: "white", fontSize: "10px" }}>
                              {(userStackedSou * tokenPrice).toFixed(3)} USD
                            </span>
                            <h2 className="accord-content-second-title">
                              Locked
                            </h2>
                            <h2 className="accord-second-sub-title">
                              {userLockedSou} H2ON
                            </h2>
                            </div>
                       
                        
                         
                          </div>
                        )}
                      </div>
                      <div className="col-md-3">
                        {userStackedSou === null ||userStackedSou === "" ? null : (
                          <div className='my-coll-test'>
                          
                       
                          <div>
                          <h2 className="accord-content-second-title">
                              Earned
                            </h2>
                            <h2 className="accord-second-sub-title">
                              {southernPend} H2ON
                            </h2>
                            <span style={{ color: "white", fontSize: "10px" }}>
                              {(southernPend * tokenPrice).toFixed(3)} USD
                            </span>
                            <br />
                            <button
                             class="btn btn-md btn-auto btn-grad no-change"
                              onClick={() => handleCompound("1")}
                            >
                              Compound 
                            </button>
                          </div>
                         
                          </div>
                        )}
                      </div>
                      <div className="col-md-3">
                        <span className="accord-content-second-title">
                          <span className="span-blue"> Lock</span> Duration :
                        </span>
                        <h2 className="accord-second-sub-title">90 Days</h2>
    
                        <span className="accord-content-second-title">
                          <span className="span-blue"> Deposit</span> Fee :
                        </span>
                        <h2 className="accord-second-sub-title"> 0.00 %</h2>
    
                        <span className="accord-content-second-title">
                          <span className="span-blue"> Withdraw</span> Fee :
                        </span>
                        <h2 className="accord-second-sub-title">0.15 %</h2>
                        <div className="box-glow">
                          {/* <h2 className="accord-glow-title">START STAKING</h2> */}
                          {userStackedSou === null || userStackedSou === 0 || userStackedSou ==""|| userStackedSou =="0.0"|| userStackedSou =="0.00"|| userStackedSou =="0.000"|| userStackedSou =="0.0000"|| userStackedSou =="0.00000" ? (
                            <a
                              onClick={() => handleOpen("1")}
                              // value="0"
                              // onPress={handleOpen(value)}
                              class="btn btn-md btn-auto btn-grad no-change"
                            >
                              Stake
                            </a>
                          ) : (
                            <div style={{flexDirection:'row'}}>
                            <div class="btn btn-md btn-auto btn-grad no-change">
                                      <MinusOutlined
                                style={{
                                  color: "white",
                                  
                                  fontSize: "20px",
                                }}
                                onClick={() => handleOpenPay("1")}
                              />
                            </div>
                           
                            <div class="btn btn-md btn-auto btn-grad no-change">
                              <PlusOutlined
                                style={{
                                  color: "white",
                                  
                                  fontSize: "20px",
                                }}
                                onClick={() => handleOpen("1")}
                              />
                            </div>
                     
                          </div>
                          )}
                        </div>
                      </div>
                 
                    </div>
                  </div>
                </div>
              </div>
          
            </div>
              {/* Indian Pool */}
        <div className="ac-body" >
              <div className="accordian-item">
                <div className="accordian-item-header" onClick={()=>setViewMore2(!viewMore2)}>
                  <div className="setrow">
                    <div className="col-md-3">
                      <div className="setrow">
                       <div className="col-md-6">
                          <img src={indianpool} className="icon-pool" />
                        </div>
                        <div className="col-md-6">
                          <div className="afterimage">
                            <h2 className="accord-title">Indian Pool</h2>
  {/* <h2 className="accord-sub-title">Stake H2ON</h2> */}
                            <h2 className="accord-title-span-sm">Lock: 180 Days</h2>
                          </div>
                        </div>
                      </div>
                    </div>
                    {indianPend === null || indianPend=="" || indianPend==0|| indianPend=="0.0"|| indianPend=="0.00"|| indianPend=="0.000"|| indianPend=="0.0000"|| indianPend=="0.00000" ? 
                     <div className="col-md-2">
                     <h2 className="accord-title-span">H2ON Earned</h2>
                     <h2 className="accord-sub-title">
                       0.00000 H2ON
                     </h2>
                   </div>
                    : (
                      <div className="col-md-2">
                        <h2 className="accord-title-span">H2ON Earned</h2>
                        <h2 className="accord-sub-title">
                          {indianPend} H2ON
                        </h2>
                      </div>
                    )}
                    <div className="col-md-2">
                      <h2 className="accord-title-span">APR</h2>
                      <h2 className="accord-sub-title">{indianApr}%</h2>
                    </div>
                    <div className="col-md-2">
                      <h2 className="accord-title-span">Total Staked</h2>
                      <h2 className="accord-sub-title">
                        {indianTotalStaked}{" "}
                        H2ON
                      </h2>
                    </div>
                    <div className="col-md-2">
                      <h2 className="accord-title-span">Ends In</h2>
                      <h2 className="accord-sub-title">{remainCon} Blocks</h2>
                    </div>
                    <div class="btn btn-md btn-auto btn-grad-head no-change" >
                    <PlusOutlined
                        style={{
                          color: "white",
                          
                          fontSize: "20px",
                        }}
                        onClick={()=>setViewMore2(!viewMore2)}
                      />
                     
                      {/* <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="white" className="bi bi-plus-lg" viewBox="0 0 16 16" onClick={() => setViewMore2(!viewMore2)}>
                        <path fill-rule="evenodd" d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2Z"/>
                      </svg> */}
                    </div>
                  </div>
                </div>
                <div
                  className="accordian-item-body"
                  style={{ maxHeight: viewMore2 ? 300 : 0 }}
                >
                  <div className="accordian-item-body-content">
                    <div className="setrow">
                      <div className="col-md-3">
                        <div >
                         
             
                            <h2 className="accord-title-span-block">Starts In</h2>
                   
                        </div>
                        <h2 className="accord-sub-title-block">
                        19,343,440 Blocks
                        </h2>
                        <div>
                        <button className="accord-content-sub-title-button"
                          onClick={() =>
                            window.open(
                              "https://bscscan.com/address/0xe0e81c29a68bfdd7c48072fd94e7c58f1f0146c1",
                              "_blank",
                            )
                          } 
                        >
                        {/* <h2
                          className="accord-content-sub-title"
                        
                        > */}
                          See Token Info{" "}
                          {/* <i className=" data-arrow fa fa-long-arrow-right"></i> */}
                          {/* <img src={arrow} style={{width:'5%'}}                     onClick={() =>
                            window.open(
                              "https://bscscan.com/address/0xe0e81c29a68bfdd7c48072fd94e7c58f1f0146c1",
                              "_blank",
                            )
                          }/> */}
                        {/* </h2> */}
                        </button>
                        </div>
                        <button
                          className="accord-content-mini-sub-title-button"
                          onClick={() =>
                            window.open(
                              "https://www.h2o-securities.com/",
                              "_blank",
                            )
                          }
 
                        >
                          View Project Site{" "}
                       
                        </button>
    
    
                        <br />
                        <br />
                        <div className="space">
                          <img
                            src={certik}
                            alt=""
                            style={{ height: "30px", resizeMode: "contain" }}
                          />
                        </div>
                      </div>
                      <div className="col-md-3">
                        {userStackedInd === null ||userStackedInd === "" ? null : (
                          <div className='my-coll-test'>
                            <div>
                            <h2 className="accord-content-second-title">
                              Staked
                            </h2>
                            <h2 className="accord-second-sub-title">
                              {userStackedInd} H2ON
                            </h2>
                            <span style={{ color: "white", fontSize: "10px" }}>
                              {(userStackedInd * tokenPrice).toFixed(3)} USD
                            </span>
                            <h2 className="accord-content-second-title">
                              Locked
                            </h2>
                            <h2 className="accord-second-sub-title">
                              {userLockedInd} H2ON
                            </h2>
                            </div>
                            
                          </div>
                        )}
                      </div>
                      <div className="col-md-3">
                        {userStackedInd === null ||userStackedInd === "" ? null : (
                          <div className='my-coll-test'>
                            <div>
                            <h2 className="accord-content-second-title">
                              Earned
                            </h2>
                            <h2 className="accord-second-sub-title">
                              {indianPend} H2ON
                            </h2>
                            <span style={{ color: "white", fontSize: "10px" }}>
                              {(indianPend * tokenPrice).toFixed(3)} USD
                            </span>
                            <br />
                            <button
                              class="btn btn-md btn-auto btn-grad no-change"
                              onClick={() => handleCompound("2")}
                            >
                              Compound 
                            </button>
                            </div>
                            
                           
                       
                          </div>
                        )}
                      </div>
                      <div className="col-md-3">
                        <span className="accord-content-second-title">
                          <span className="span-blue"> Lock</span> Duration :
                        </span>
                        <h2 className="accord-second-sub-title">180 Days</h2>
    
                        <span className="accord-content-second-title">
                          <span className="span-blue"> Deposit</span> Fee :
                        </span>
                        <h2 className="accord-second-sub-title"> 0.00 %</h2>
    
                        <span className="accord-content-second-title">
                          <span className="span-blue"> Withdraw</span> Fee :
                        </span>
                        <h2 className="accord-second-sub-title">0.15 %</h2>
                        <div className="box-glow">
                          {/* <h2 className="accord-glow-title">START STAKING</h2> */}
                          {userLockedInd === null || userLockedInd === 0 || userLockedInd ==""|| userLockedInd =="0.0"|| userLockedInd =="0.00"|| userLockedInd =="0.000"|| userLockedInd =="0.0000"|| userLockedInd =="0.00000" ? (
                            <a
                              onClick={() => handleOpen("2")}
                              // value="0"
                              // onPress={handleOpen(value)}
                              class="btn btn-md btn-auto btn-grad no-change"
                            >
                              Stake
                            </a>
                          ) : (
                            <div style={{flexDirection:'row'}}>
                            <div class="btn btn-md btn-auto btn-grad no-change">
                                      <MinusOutlined
                                style={{
                                  color: "white",
                                  
                                  fontSize: "20px",
                                }}
                                onClick={() => handleOpenPay("2")}
                           
                              />
                            </div>
                           
                            <div class="btn btn-md btn-auto btn-grad no-change">
                              <PlusOutlined
                                style={{
                                  color: "white",
                                  
                                  fontSize: "20px",
                                }}
                                onClick={() => handleOpen("2")}
                              />
                            </div>
                     
                          </div>
                          )}
                        </div>
                      </div>
                     
                    </div>
                  </div>
                </div>
              </div>
            </div>
                  {/* Atlantic Pool */}
        <div className="ac-body" >
              <div className="accordian-item">
                <div className="accordian-item-header" onClick={()=>setViewMore3(!viewMore3)}>
                  <div className="setrow">
                    <div className="col-md-3">
                      <div className="setrow">
                        <div className="col-md-6">
                          <img src={atlanticpool} className="icon-pool" />
                        </div>
                        <div className="col-md-6">
                          <div className="afterimage">
                            <h2 className="accord-title">Atlantic Pool</h2>
  {/* <h2 className="accord-sub-title">Stake H2ON</h2> */}
                            <h2 className="accord-title-span-sm">Lock: 365 Days</h2>
                          </div>
                        </div>
                      </div>
                    </div>
                    {atlanticPend === null || atlanticPend==""|| atlanticPend==0|| atlanticPend=="0.0"|| atlanticPend=="0.00"|| atlanticPend=="0.000"|| atlanticPend=="0.0000"|| atlanticPend=="0.00000" ? 
                     <div className="col-md-2">
                     <h2 className="accord-title-span">H2ON Earned</h2>
                     <h2 className="accord-sub-title">
                       0.00000 H2ON
                     </h2>
                   </div>
                    : (
                      <div className="col-md-2">
                        <h2 className="accord-title-span">H2ON Earned</h2>
                        <h2 className="accord-sub-title">
                          {atlanticPend} H2ON
                        </h2>
                      </div>
                    )}
                    <div className="col-md-2">
                      <h2 className="accord-title-span">APR</h2>
                      <h2 className="accord-sub-title">{atlanticApr}%</h2>
                    </div>
                    <div className="col-md-2">
                      <h2 className="accord-title-span">Total Staked</h2>
                      <h2 className="accord-sub-title">
                        {atlanticTotalStaked}{" "}
                        H2ON
                      </h2>
                    </div>
                    <div className="col-md-2">
                      <h2 className="accord-title-span">Ends In</h2>
                      <h2 className="accord-sub-title">{remainCon} Blocks</h2>
                    </div>
                    <div class="btn btn-md btn-auto btn-grad-head no-change" >
                    <PlusOutlined
                        style={{
                          color: "white",
                          
                          fontSize: "20px",
                        }}
                        onClick={()=>setViewMore3(!viewMore3)}
                      />
                     
                      {/* <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="white" className="bi bi-plus-lg" viewBox="0 0 16 16" onClick={() => setViewMore3(!viewMore3)}>
                        <path fill-rule="evenodd" d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2Z"/>
                      </svg> */}
                    </div>
                  </div>
                </div>
                <div
                  className="accordian-item-body"
                  style={{ maxHeight: viewMore3 ? 300 : 0 }}
                >
                  <div className="accordian-item-body-content">
                    <div className="setrow">
                      <div className="col-md-3">
                        <div >
                        
 
                            <h2 className="accord-title-span-block">Starts In</h2>
       
                        </div>
                        <h2 className="accord-sub-title-block">
                        19,343,440  Blocks
                        </h2>
                        <div>
                        <button className="accord-content-sub-title-button"
                          onClick={() =>
                            window.open(
                              "https://bscscan.com/address/0xe0e81c29a68bfdd7c48072fd94e7c58f1f0146c1",
                              "_blank",
                            )
                          } 
                        >
                        {/* <h2
                          className="accord-content-sub-title"
                        
                        > */}
                          See Token Info{" "}
                          {/* <i className=" data-arrow fa fa-long-arrow-right"></i> */}
                          {/* <img src={arrow} style={{width:'5%'}}                     onClick={() =>
                            window.open(
                              "https://bscscan.com/address/0xe0e81c29a68bfdd7c48072fd94e7c58f1f0146c1",
                              "_blank",
                            )
                          }/> */}
                        {/* </h2> */}
                        </button>
                        </div>
                        <button
                          className="accord-content-mini-sub-title-button"
                          onClick={() =>
                            window.open(
                              "https://www.h2o-securities.com/",
                              "_blank",
                            )
                          }
 
                        >
                          View Project Site{" "}
                       
                        </button>
    
    
                        <br />
                        <br />
                        <div className="space">
                          <img
                            src={certik}
                            alt=""
                            style={{ height: "30px", resizeMode: "contain" }}
                          />
                        </div>
                      </div>
                      <div className="col-md-3">
                        {userStackedAtl === null ||userStackedAtl === "" ? null : (
                          <div className='my-coll-test'>
                           <div>
                           <h2 className="accord-content-second-title">
                              Staked
                            </h2>
                            <h2 className="accord-second-sub-title">
                              {userStackedAtl} H2ON
                            </h2>
                            <span style={{ color: "white", fontSize: "10px" }}>
                              {(userStackedAtl * tokenPrice).toFixed(3)} USD
                            </span>
                            <h2 className="accord-content-second-title">
                              Locked
                            </h2>
                            <h2 className="accord-second-sub-title">
                              {userLockedAlt} H2ON
                            </h2>
                           </div>
                            
                          </div>
                        )}
                      </div>
                      <div className="col-md-3">
                        {userStackedAtl === null ||userStackedAtl === "" ? null : (
                          <div className='my-coll-test'>
                          
                          <div>
                          <h2 className="accord-content-second-title">
                              Earned
                            </h2>
                            <h2 className="accord-second-sub-title">
                              {atlanticPend} H2ON
                            </h2>
                            <span style={{ color: "white", fontSize: "10px" }}>
                              {(atlanticPend * tokenPrice).toFixed(3)} USD
                            </span>
                            <br />
                            <button
                            class="btn btn-md btn-auto btn-grad no-change"
                              onClick={() => handleCompound("3")}
                            >
                              Compound 
                            </button>
                          </div>
                        
                          </div>
                        )}
                      </div>
                      <div className="col-md-3">
                        <span className="accord-content-second-title">
                          <span className="span-blue"> Lock</span> Duration :
                        </span>
                        <h2 className="accord-second-sub-title">365 Days</h2>
    
                        <span className="accord-content-second-title">
                          <span className="span-blue"> Deposit</span> Fee :
                        </span>
                        <h2 className="accord-second-sub-title"> 0.00 %</h2>
    
                        <span className="accord-content-second-title">
                          <span className="span-blue"> Withdraw</span> Fee :
                        </span>
                        <h2 className="accord-second-sub-title">0.15 %</h2>
                        <div className="box-glow">
                          {/* <h2 className="accord-glow-title">START STAKING</h2> */}
                          {userStackedAtl === null || userStackedAtl === 0 || userStackedAtl ==""|| userStackedAtl =="0.0"|| userStackedAtl =="0.00"|| userStackedAtl =="0.000"|| userStackedAtl =="0.0000"|| userStackedAtl =="0.00000" ? (
                            <a
                              onClick={() => handleOpen("3")}
                              // value="0"
                              // onPress={handleOpen(value)}
                              class="btn btn-md btn-auto btn-grad no-change"
                            >
                              Stake
                            </a>
                          ) : (
                            <div style={{flexDirection:'row'}}>
                            <div class="btn btn-md btn-auto btn-grad no-change">
                                      <MinusOutlined
                                style={{
                                  color: "white",
                                  
                                  fontSize: "20px",
                                }}
                                onClick={() => handleOpenPay("3")}
                              />
                            </div>
                           
                            <div class="btn btn-md btn-auto btn-grad no-change">
                              <PlusOutlined
                                style={{
                                  color: "white",
                                  
                                  fontSize: "20px",
                                }}
                                onClick={() => handleOpen("3")}
                              />
                            </div>
                     
                          </div>
                          )}
                        </div>
                      </div>
                    
                    </div>
                  </div>
                </div>
              </div>
            </div>
                            {/* Pacific Pool */}
        <div className="ac-body">
              <div className="accordian-item">
                <div className="accordian-item-header"  onClick={()=>setViewMore4(!viewMore4)}>
                  <div className="setrow">
                    <div className="col-md-3">
                      <div className="setrow">
                      <div className="col-md-6">
                          <img src={pacificpool} className="icon-pool" />
                        </div>
                        <div className="col-md-6">
                          <div className="afterimage">
                            <h2 className="accord-title">Pacific Pool</h2>
  {/* <h2 className="accord-sub-title">Stake H2ON</h2> */}
                            <h2 className="accord-title-span-sm">Lock: 730 Days</h2>
                          </div>
                        </div>
                      </div>
                    </div>
                    {pacificPend === null || pacificPend===""|| pacificPend===0 || pacificPend==="0.0000"|| pacificPend==="0.000"|| pacificPend==="0.00000" ? 
                     <div className="col-md-2">
                     <h2 className="accord-title-span">H2ON Earned</h2>
                     <h2 className="accord-sub-title">
                       0.00000 H2ON
                     </h2>
                   </div>
                    : (
                      <div className="col-md-2">
                        <h2 className="accord-title-span">H2ON Earned</h2>
                        <h2 className="accord-sub-title">
                          {pacificPend} H2ON
                        </h2>
                      </div>
                    )}
                    <div className="col-md-2">
                      <h2 className="accord-title-span">APR</h2>
                      <h2 className="accord-sub-title">{pacificApr}%</h2>
                    </div>
                    <div className="col-md-2">
                      <h2 className="accord-title-span">Total Staked</h2>
                      <h2 className="accord-sub-title">
                        {pacificTotalStaked}{" "}
                        H2ON
                      </h2>
                    </div>
                    <div className="col-md-2">
                      <h2 className="accord-title-span">Ends In</h2>
                      <h2 className="accord-sub-title">{remainCon2} Blocks</h2>
                    </div>
                    <div class="btn btn-md btn-auto btn-grad-head no-change" >
                    <PlusOutlined
                        style={{
                          color: "white",
                          
                          fontSize: "20px",
                        }}
                        onClick={()=>setViewMore4(!viewMore4)}
                      />
                     
                      {/* <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="white" className="bi bi-plus-lg" viewBox="0 0 16 16" onClick={() => setViewMore4(!viewMore4)}>
                        <path fill-rule="evenodd" d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2Z"/>
                      </svg> */}
                    </div>
                  </div>
                </div>
                <div
                  className="accordian-item-body"
                  style={{ maxHeight: viewMore4 ? 300 : 0 }}
                >
                  <div className="accordian-item-body-content">
                    <div className="setrow">
                      <div className="col-md-3">
                        <div >
                         
                          
                            <h2 className="accord-title-span-block">Starts In</h2>
                     
                        </div>
                        <h2 className="accord-sub-title-block">
                        19,343,146 Blocks
                        </h2>
                        <div>
                        <button className="accord-content-sub-title-button"
                          onClick={() =>
                            window.open(
                              "https://bscscan.com/address/0xe0e81c29a68bfdd7c48072fd94e7c58f1f0146c1",
                              "_blank",
                            )
                          } 
                        >
                        {/* <h2
                          className="accord-content-sub-title"
                        
                        > */}
                          See Token Info{" "}
                          {/* <i className=" data-arrow fa fa-long-arrow-right"></i> */}
                          {/* <img src={arrow} style={{width:'5%'}}                     onClick={() =>
                            window.open(
                              "https://bscscan.com/address/0xe0e81c29a68bfdd7c48072fd94e7c58f1f0146c1",
                              "_blank",
                            )
                          }/> */}
                        {/* </h2> */}
                        </button>
                        </div>
                        <button
                          className="accord-content-mini-sub-title-button"
                          onClick={() =>
                            window.open(
                              "https://www.h2o-securities.com/",
                              "_blank",
                            )
                          }
 
                        >
                          View Project Site{" "}
                       
                        </button>
    
    
                        <br />
                        <br />
                        <div className="space">
                          <img
                            src={certik}
                            alt=""
                            style={{ height: "30px", resizeMode: "contain" }}
                          />
                        </div>
                      </div>
                      <div className="col-md-3">
                        {userLockedPac === null ||userLockedPac === "" ? null : (
                          <div className='my-coll-test'>
                           <div>
                           <h2 className="accord-content-second-title">
                              Staked
                            </h2>
                            <h2 className="accord-second-sub-title">
                              {userLockedPac} H2ON
                            </h2>
                            <span style={{ color: "white", fontSize: "10px" }}>
                              {(userLockedPac * tokenPrice).toFixed(3)} USD
                            </span>
                            <h2 className="accord-content-second-title">
                              Locked
                            </h2>
                            <h2 className="accord-second-sub-title">
                              {userLockedPac} H2ON
                            </h2>
                           </div>
                            
                          </div>
                        )}
                      </div>
                      <div className="col-md-3">
                        {userLockedPac === null ||userLockedPac === "" ? null : (
                          <div className='my-coll-test'>
                         
                            <div>
                            <h2 className="accord-content-second-title">
                              Earned
                            </h2>
                            <h2 className="accord-second-sub-title">
                              {pacificPend} H2ON
                            </h2>
                            <span style={{ color: "white", fontSize: "10px" }}>
                              {(pacificPend * tokenPrice).toFixed(3)} USD
                            </span>
                            <br />
                            <button
                              class="btn btn-md btn-auto btn-grad no-change"
                              onClick={() => handleCompound("4")}
                            >
                              Compound 
                            </button>
                            </div>
                        
                          </div>
                        )}
                      </div>
                      <div className="col-md-3">
                        <span className="accord-content-second-title">
                          <span className="span-blue"> Lock</span> Duration :
                        </span>
                        <h2 className="accord-second-sub-title">730 Days</h2>
    
                        <span className="accord-content-second-title">
                          <span className="span-blue"> Deposit</span> Fee :
                        </span>
                        <h2 className="accord-second-sub-title"> 0.00 %</h2>
    
                        <span className="accord-content-second-title">
                          <span className="span-blue"> Withdraw</span> Fee :
                        </span>
                        <h2 className="accord-second-sub-title">0.15 %</h2>
                        <div className="box-glow">
                          {/* <h2 className="accord-glow-title">START STAKING</h2> */}
                          {userStackedPac === null || userStackedPac === 0 || userStackedPac ===""|| userStackedPac ==="0.0"|| userStackedPac ==="0.00"|| userStackedPac ==="0.000"|| userStackedPac ==="0.0000" ? (
                            <a
                              onClick={() => handleOpen("4")}
                              // value="0"
                              // onPress={handleOpen(value)}
                              class="btn btn-md btn-auto btn-grad no-change"
                            >
                              Stake
                            </a>
                          ) : (
                            <div style={{flexDirection:'row'}}>
                            <div class="btn btn-md btn-auto btn-grad no-change">
                                      <MinusOutlined
                                style={{
                                  color: "white",
                                  
                                  fontSize: "20px",
                                }}
                                onClick={() => handleOpenPay("4")}
                           
                              />
                            </div>
                           
                            <div class="btn btn-md btn-auto btn-grad no-change">
                              <PlusOutlined
                                style={{
                                  color: "white",
                                  
                                  fontSize: "20px",
                                }}
                                onClick={() => handleOpen("4")}
                              />
                            </div>
                     
                          </div>
                          )}
                        </div>
                      </div>
                    
                    </div>
                  </div>
                </div>
              </div>
            </div>
          <div style={{padding:"5%"}}>
            <img src={footer} style={{width:"20%", cursor: "pointer"}}
            onClick={() =>
              window.open(
                "https://www.h2o-securities.com/",
                "_blank",
              )
            }
            />
            <div className='footer-div'>
                <div className='footer-icons'
                  onClick={() =>
                    window.open(
                      "https://twitter.com/H2O_Coin",
                      "_blank",
                    )
                  }
                >
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-twitter" viewBox="0 0 16 16">
                    <path d="M5.026 15c6.038 0 9.341-5.003 9.341-9.334 0-.14 0-.282-.006-.422A6.685 6.685 0 0 0 16 3.542a6.658 6.658 0 0 1-1.889.518 3.301 3.301 0 0 0 1.447-1.817 6.533 6.533 0 0 1-2.087.793A3.286 3.286 0 0 0 7.875 6.03a9.325 9.325 0 0 1-6.767-3.429 3.289 3.289 0 0 0 1.018 4.382A3.323 3.323 0 0 1 .64 6.575v.045a3.288 3.288 0 0 0 2.632 3.218 3.203 3.203 0 0 1-.865.115 3.23 3.23 0 0 1-.614-.057 3.283 3.283 0 0 0 3.067 2.277A6.588 6.588 0 0 1 .78 13.58a6.32 6.32 0 0 1-.78-.045A9.344 9.344 0 0 0 5.026 15z"/>
                  </svg>
                </div>
                <div className='footer-icons'
                 onClick={() =>
                  window.open(
                    "https://t.me/H2OSecuritiesForum",
                    "_blank",
                  )
                }
                >
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-telegram" viewBox="0 0 16 16">
                  <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.287 5.906c-.778.324-2.334.994-4.666 2.01-.378.15-.577.298-.595.442-.03.243.275.339.69.47l.175.055c.408.133.958.288 1.243.294.26.006.549-.1.868-.32 2.179-1.471 3.304-2.214 3.374-2.23.05-.012.12-.026.166.016.047.041.042.12.037.141-.03.129-1.227 1.241-1.846 1.817-.193.18-.33.307-.358.336a8.154 8.154 0 0 1-.188.186c-.38.366-.664.64.015 1.088.327.216.589.393.85.571.284.194.568.387.936.629.093.06.183.125.27.187.331.236.63.448.997.414.214-.02.435-.22.547-.82.265-1.417.786-4.486.906-5.751a1.426 1.426 0 0 0-.013-.315.337.337 0 0 0-.114-.217.526.526 0 0 0-.31-.093c-.3.005-.763.166-2.984 1.09z"/>
                </svg>
                </div>
                <div className='footer-icons'
                 onClick={() =>
                  window.open(
                    "https://www.linkedin.com/company/h2o-securities/",
                    "_blank",
                  )
                }
                >
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-linkedin" viewBox="0 0 16 16">
                    <path d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854V1.146zm4.943 12.248V6.169H2.542v7.225h2.401zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248-.822 0-1.359.54-1.359 1.248 0 .694.521 1.248 1.327 1.248h.016zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016a5.54 5.54 0 0 1 .016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225h2.4z"/>
                  </svg>
                </div>
                <div className='footer-icons'
                 onClick={() =>
                  window.open(
                    "https://medium.com/h2o-securities",
                    "_blank",
                  )
                }
                >
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-medium" viewBox="0 0 16 16">
                  <path d="M9.025 8c0 2.485-2.02 4.5-4.513 4.5A4.506 4.506 0 0 1 0 8c0-2.486 2.02-4.5 4.512-4.5A4.506 4.506 0 0 1 9.025 8zm4.95 0c0 2.34-1.01 4.236-2.256 4.236-1.246 0-2.256-1.897-2.256-4.236 0-2.34 1.01-4.236 2.256-4.236 1.246 0 2.256 1.897 2.256 4.236zM16 8c0 2.096-.355 3.795-.794 3.795-.438 0-.793-1.7-.793-3.795 0-2.096.355-3.795.794-3.795.438 0 .793 1.699.793 3.795z"/>
                </svg>
                </div>
                <div className='footer-icons'
                 onClick={() =>
                  window.open(
                    "https://www.facebook.com/H2OSecurities",
                    "_blank",
                  )
                }
                >
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-facebook" viewBox="0 0 16 16">
                <path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951z"/>
              </svg>
                </div>
                
            </div>
            <div>
              <p>Copyright © 2022 H2O Securities.</p>
              <p>All rights reserved.</p>
            </div>
          </div>
      </div>:
      //phones screen
       <div className='App'>
       <div className='main-header' >
       
        {isAuthenticated ?
        <div style={{flexDirection:'row'}}>
          <div>
            <img src={logo} style={{width:'25%', resizeMode:"contain"}}/>
            </div>
                 <button class="btn btn-md btn-auto btn-grad-mod-sm no-change"  onClick={() =>
                            window.open(
                              "https://www.h2o-securities.com/",
                              "_blank",
                            )
                          }>Home</button>
        <button class="btn btn-md btn-auto btn-grad-mod-sm no-change" onClick={()=>logOut()}>{userWalletMake.substring(0, 6)}...{userWalletMake.substring(userWalletMake.length - 4)}</button>
        <button class="btn btn-md btn-auto btn-grad-mod-sm no-change" onClick={()=>logOut()}>Disconnect</button>
      </div>:
          <div>
            <div>
            <img src={logo} style={{width:'25%', resizeMode:"contain"}}/>
            </div>

                  <button className='btn-glow-small-s'  onClick={() =>
                            window.open(
                              "https://www.h2o-securities.com/",
                              "_blank",
                            )
                          }>Home</button>
       
               <button className='btn-glow-small-s' onClick={()=>login()} >Connect Wallet</button>
        
          </div>
        }
    
       </div>
       {myAlertW ?  
          <div style={{marginTop:"5px"}}>
           <Stack sx={{ width: '100%' }} spacing={2}>
             
             <Alert severity="info" 
             action={
               <IconButton
                 aria-label="close"
                 color="inherit"
                 size="small"
                 onClick={() => {
                   setMyAlertW(false);
                 }}
               >
                 <CloseIcon fontSize="inherit" />
               </IconButton>
             }
             sx={{ mb: 2 }}
             >No Crypto Wallet found</Alert>
            
           </Stack>
          </div>: null
          }
        <h1 style={{ fontFamily: "Poppins, sans-serif", color: "white" }}>
          Staking Pools
        </h1>
    
      {/* Arctic Pool */}
        <div className="ac-body"   >
              <div className="accordian-item">
                <div className="accordian-item-header" onClick={()=>setViewMore(!viewMore)}>
                  <div className="setrow">
                    <div className="col-md-6">
                      <div className="row">
                   
                        <div className="afterimage">
                          <h2 className="accord-title">H2ON Arctic Pool</h2>
                          {/* <h2 className="accord-sub-title">Stake H2ON</h2> */}
                          <h2 className="accord-title-span-sm">Lock: 30 Days</h2>
                        </div>
                      </div>
                    </div>
                  
                    <div className="col-md-6">
                      <h2 className="accord-title-span">APR</h2>
                      <h2 className="accord-sub-title">{arcticApr}%</h2>
                    </div>

               
                    <div  >
                      <PlusOutlined
                        style={{
                          color: "white",
                         
                          fontSize: "20px",
                          marginTop:"10px"
                        }}
                        onClick={()=>setViewMore(!viewMore)}
                      />
                   
                      {/* <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="white" className="bi bi-plus-lg" viewBox="0 0 16 16" onClick={click} >
                        <path fill-rule="evenodd" d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2Z"/>
                      </svg> */}
                    </div>
                  </div>
                </div>
                <div
                  className="accordian-item-body"
                  style={{ maxHeight: viewMore ? 500 : 0 }}
                >
                  <div className="accordian-item-body-content">
                    <div className="setrow">
   
    
                      <div className="col-md-6">
                        {userStackedArc === null ||userStackedArc === "" ? null : (
                          <div className='my-coll-test'>
                            <div>
                              <h2 className="accord-content-second-title">
                                Staked
                              </h2>
                              <h2 className="accord-second-sub-title">
                                {userStackedArc} H2ON
                              </h2>
                              <span style={{ color: "white", fontSize: "10px" }}>
                                {(userStackedArc * tokenPrice).toFixed(3)} USD
                              </span>
                              <h2 className="accord-content-second-title">
                                Locked
                              </h2>
                              <h2 className="accord-second-sub-title">
                                {userLockedArc} H2ON
                              </h2>
                              <br />
                              <h2 className="accord-content-second-title">
                                Earned
                              </h2>
                              <h2 className="accord-second-sub-title">
                                {arcticPend} H2ON
                              </h2>
                              <span style={{ color: "white", fontSize: "10px" }}>
                                {(arcticPend * tokenPrice).toFixed(3)} USD
                              </span>
                              <br />
                              <button
                                class="btn btn-md btn-auto btn-grad-mod-sm no-change"
                                onClick={() => handleCompound("0")}
                              >
                                Compound
                              </button>
                            </div>

     
                            
                          </div>
                        )}
                      </div>
                   
                 
                      <div className="col-md-6">
                        <span className="accord-content-second-title">
                          <span className="span-blue"> Lock</span> Duration :
                        </span>
                        <h2 className="accord-second-sub-title">30 Days</h2>
    
                        <span className="accord-content-second-title">
                          <span className="span-blue"> Deposit</span> Fee :
                        </span>
                        <h2 className="accord-second-sub-title"> 2.00 %</h2>
    
                        <span className="accord-content-second-title">
                          <span className="span-blue"> Withdraw</span> Fee :
                        </span>
                        <h2 className="accord-second-sub-title">0.00 %</h2>
                        <div className="box-glow">
                          {/* <h2 className="accord-glow-title">START STAKING</h2> */}
                          {userStackedArc === null || userStackedArc === 0 || userStackedArc ==="" || userStackedArc ==="0.00000" || userStackedArc ==="0.0000"|| userStackedArc ==="0.000" ? (
                            <div>
                                                 <a
                              onClick={() => handleOpen("0")}
                              // value="0"
                              // onPress={handleOpen(value)}
                              class="btn btn-md btn-auto btn-grad-mod-sm no-change"
                            >
                              Stake
                            </a>
                             {myAlert ?  
                             <div style={{marginTop:"5px"}}>
                              <Stack sx={{ width: '100%' }} spacing={2}>
                                
                                <Alert severity="info" 
                                action={
                                  <IconButton
                                    aria-label="close"
                                    color="inherit"
                                    size="small"
                                    onClick={() => {
                                      setMyAlert(false);
                                    }}
                                  >
                                    <CloseIcon fontSize="inherit" />
                                  </IconButton>
                                }
                                sx={{ mb: 2 }}
                                >No Crypto Wallet found</Alert>
                               
                              </Stack>
                             </div>: null
                             }
                            </div>
                          ) : (
                            <div style={{flexDirection:'row'}}>
                              <div class="btn btn-md btn-auto btn-grad-mod-sm no-change">
                                        <MinusOutlined
                                  style={{
                                    color: "white",
                                    
                                    fontSize: "20px",
                                  }}
                                  onClick={() => handleOpenPay("0")}
                                />
                              </div>
                             
                              <div class="btn btn-md btn-auto btn-grad-mod-sm no-change">
                                <PlusOutlined
                                  style={{
                                    color: "white",
                                    
                                    fontSize: "20px",
                                  }}
                                  onClick={() => handleOpen("0")}
                                />
                              </div>
                       
                            </div>
                          )}
                        </div>
                      </div>
    
                   
                    </div>
                  </div>
                </div>
              </div>
            </div>
             {/* Southern Pool */}
        <div className="ac-body" >
              <div className="accordian-item">
                <div className="accordian-item-header" onClick={()=>setViewMore1(!viewMore1)}>
                  <div className="setrow">
                    <div className="col-md-6">
                      <div className="row">
                       
                        <div className="afterimage">
                          <h2 className="accord-title">H2ON Southern Pool</h2>
                          {/* <h2 className="accord-sub-title">Stake H2ON</h2> */}
                          <h2 className="accord-title-span-sm">Lock: 90 Days</h2>
                        </div>
                      </div>
                    </div>
                 
                    <div className="col-md-6">
                      <h2 className="accord-title-span">APR</h2>
                      <h2 className="accord-sub-title">{southernApr}%</h2>
                    </div>
                    
                 
                    <div className="morebutton">
                    <PlusOutlined
                        style={{
                          color: "white",
                          
                          fontSize: "20px",
                          marginTop:"10px"
                        }}
                        onClick={()=>setViewMore1(!viewMore1)}
                      />
                     
                      {/* <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="white" className="bi bi-plus-lg" viewBox="0 0 16 16" onClick={() => setViewMore1(!viewMore1)}>
                        <path fill-rule="evenodd" d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2Z"/>
                      </svg> */}
                    </div>
                  </div>
                </div>
                <div
                  className="accordian-item-body"
                  style={{ maxHeight: viewMore1 ? 300 : 0 }}
                >
                  <div className="accordian-item-body-content">
                    <div className="setrow">
                 
    
                      <div className="col-md-6">
                        {userStackedSou === null ||userStackedSou === "" ? null : (
                          <div className='my-coll-test'>
                            <div>
                            <h2 className="accord-content-second-title">
                              Staked
                            </h2>
                            <h2 className="accord-second-sub-title">
                              {userStackedSou} H2ON
                            </h2>
                            <span style={{ color: "white", fontSize: "10px" }}>
                              {(userStackedSou * tokenPrice).toFixed(3)} USD
                            </span>
                            <h2 className="accord-content-second-title">
                              Locked
                            </h2>
                            <h2 className="accord-second-sub-title">
                              {userLockedSou} H2ON
                            </h2>
                            <br/>
                            <h2 className="accord-content-second-title">
                              Earned
                            </h2>
                            <h2 className="accord-second-sub-title">
                              {southernPend} H2ON
                            </h2>
                            <span style={{ color: "white", fontSize: "10px" }}>
                              {(southernPend * tokenPrice).toFixed(3)} USD
                            </span>
                            <br />
                            <button
                               class="btn btn-md btn-auto btn-grad-mod-sm no-change"
                              onClick={() => handleCompound("1")}
                            >
                              Compound 
                            </button>
                            </div>
                       
                        
                         
                          </div>
                        )}
                      </div>
                     
                      <div className="col-md-6">
                        <span className="accord-content-second-title">
                          <span className="span-blue"> Lock</span> Duration :
                        </span>
                        <h2 className="accord-second-sub-title">90 Days</h2>
    
                        <span className="accord-content-second-title">
                          <span className="span-blue"> Deposit</span> Fee :
                        </span>
                        <h2 className="accord-second-sub-title"> 0.00 %</h2>
    
                        <span className="accord-content-second-title">
                          <span className="span-blue"> Withdraw</span> Fee :
                        </span>
                        <h2 className="accord-second-sub-title">0.15 %</h2>
                        <div className="box-glow">
                          {/* <h2 className="accord-glow-title">START STAKING</h2> */}
                          {userStackedSou === null || userStackedSou === 0 || userStackedSou ==""|| userStackedSou =="0.0"|| userStackedSou =="0.00"|| userStackedSou =="0.000"|| userStackedSou =="0.0000"|| userStackedSou =="0.00000" ? (
                            <a
                              onClick={() => handleOpen("1")}
                              // value="0"
                              // onPress={handleOpen(value)}
                              class="btn btn-md btn-auto btn-grad-mod-sm no-change"
                            >
                              Stake
                            </a>
                          ) : (
                            <div style={{flexDirection:'row'}}>
                            <div  class="btn btn-md btn-auto btn-grad-mod-sm no-change">
                                      <MinusOutlined
                                style={{
                                  color: "white",
                                  
                                  fontSize: "20px",
                                }}
                                onClick={() => handleOpenPay("1")}
                              />
                            </div>
                           
                            <div  class="btn btn-md btn-auto btn-grad-mod-sm no-change">
                              <PlusOutlined
                                style={{
                                  color: "white",
                                  
                                  fontSize: "20px",
                                }}
                                onClick={() => handleOpen("1")}
                              />
                            </div>
                     
                          </div>
                          )}
                        </div>
                      </div>
                 
                    </div>
                  </div>
                </div>
              </div>
            </div>
              {/* Indian Pool */}
        <div className="ac-body" >
              <div className="accordian-item">
                <div className="accordian-item-header" onClick={()=>setViewMore2(!viewMore2)}>
                  <div className="setrow">
                    <div className="col-md-6">
                      <div className="row">
                    
                        <div className="afterimage">
                          <h2 className="accord-title">H2ON Indian Pool</h2>
{/* <h2 className="accord-sub-title">Stake H2ON</h2> */}
                          <h2 className="accord-title-span-sm">Lock: 180 Days</h2>
                        </div>
                      </div>
                    </div>
                 
                    <div className="col-md-6">
                      <h2 className="accord-title-span">APR</h2>
                      <h2 className="accord-sub-title">{indianApr}%</h2>
                    </div>
                
                    
                    <div className="morebutton" >
                    <PlusOutlined
                        style={{
                          color: "white",
                          
                          fontSize: "20px",
                          marginTop:"10px"
                        }}
                        onClick={()=>setViewMore2(!viewMore2)}
                      />
                     
                      {/* <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="white" className="bi bi-plus-lg" viewBox="0 0 16 16" onClick={() => setViewMore2(!viewMore2)}>
                        <path fill-rule="evenodd" d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2Z"/>
                      </svg> */}
                    </div>
                  </div>
                </div>
                <div
                  className="accordian-item-body"
                  style={{ maxHeight: viewMore2 ? 300 : 0 }}
                >
                  <div className="accordian-item-body-content">
                    <div className="setrow">
                  
                      <div className="col-md-6">
                        {userStackedInd === null ||userStackedInd === "" ? null : (
                          <div className='my-coll-test'>
                            <div>
                            <h2 className="accord-content-second-title">
                              Staked
                            </h2>
                            <h2 className="accord-second-sub-title">
                              {userStackedInd} H2ON
                            </h2>
                            <span style={{ color: "white", fontSize: "10px" }}>
                              {(userStackedInd * tokenPrice).toFixed(3)} USD
                            </span>
                            <h2 className="accord-content-second-title">
                              Locked
                            </h2>
                            <h2 className="accord-second-sub-title">
                              {userLockedInd} H2ON
                            </h2>
                            <br/>
                            <h2 className="accord-content-second-title">
                              Earned
                            </h2>
                            <h2 className="accord-second-sub-title">
                              {indianPend} H2ON
                            </h2>
                            <span style={{ color: "white", fontSize: "10px" }}>
                              {(indianPend * tokenPrice).toFixed(3)} USD
                            </span>
                            <br />
                            <button
                               class="btn btn-md btn-auto btn-grad-mod-sm no-change"
                              onClick={() => handleCompound("2")}
                            >
                              Compound 
                            </button>
                            </div>
                            
                          </div>
                        )}
                      </div>
                    
                      <div className="col-md-6">
                        <span className="accord-content-second-title">
                          <span className="span-blue"> Lock</span> Duration :
                        </span>
                        <h2 className="accord-second-sub-title">180 Days</h2>
    
                        <span className="accord-content-second-title">
                          <span className="span-blue"> Deposit</span> Fee :
                        </span>
                        <h2 className="accord-second-sub-title"> 0.00 %</h2>
    
                        <span className="accord-content-second-title">
                          <span className="span-blue"> Withdraw</span> Fee :
                        </span>
                        <h2 className="accord-second-sub-title">0.15 %</h2>
                        <div className="box-glow">
                          {/* <h2 className="accord-glow-title">START STAKING</h2> */}
                          {userLockedInd === null || userLockedInd === 0 || userLockedInd ==""|| userLockedInd =="0.0"|| userLockedInd =="0.00"|| userLockedInd =="0.000"|| userLockedInd =="0.0000"|| userLockedInd =="0.00000" ? (
                            <a
                              onClick={() => handleOpen("2")}
                              // value="0"
                              // onPress={handleOpen(value)}
                              class="btn btn-md btn-auto btn-grad-mod-sm no-change"
                            >
                              Stake
                            </a>
                          ) : (
                            <div style={{flexDirection:'row'}}>
                            <div  class="btn btn-md btn-auto btn-grad-mod-sm no-change">
                                      <MinusOutlined
                                style={{
                                  color: "white",
                                  
                                  fontSize: "20px",
                                }}
                                onClick={() => handleOpenPay("2")}
                              />
                            </div>
                           
                            <div  class="btn btn-md btn-auto btn-grad-mod-sm no-change">
                              <PlusOutlined
                                style={{
                                  color: "white",
                                  
                                  fontSize: "20px",
                                 
                                }}
                                onClick={() => handleOpen("2")}
                              />
                            </div>
                     
                          </div>
                          )}
                        </div>
                      </div>
                     
                    </div>
                  </div>
                </div>
              </div>
            </div>
                  {/* Atlantic Pool */}
        <div className="ac-body">
              <div className="accordian-item">
                <div className="accordian-item-header" onClick={()=>setViewMore3(!viewMore3)}>
                  <div className="setrow">
                    <div className="col-md-6">
                      <div className="row">
                
                        <div className="afterimage">
                          <h2 className="accord-title">H2ON Atlantic Pool</h2>
{/* <h2 className="accord-sub-title">Stake H2ON</h2> */}
                          <h2 className="accord-title-span-sm">Lock: 365 Days</h2>
                        </div>
                      </div>
                    </div>
                   
                    <div className="col-md-6">
                      <h2 className="accord-title-span">APR</h2>
                      <h2 className="accord-sub-title">{atlanticApr}%</h2>
                    </div>
            
                    <div className="morebutton" >
                    <PlusOutlined
                        style={{
                          color: "white",
                          
                          fontSize: "20px",
                          marginTop:"10px"
                        }}
                        onClick={()=>setViewMore3(!viewMore3)}
                      />
                     
                      {/* <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="white" className="bi bi-plus-lg" viewBox="0 0 16 16" onClick={() => setViewMore3(!viewMore3)}>
                        <path fill-rule="evenodd" d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2Z"/>
                      </svg> */}
                    </div>
                  </div>
                </div>
                <div
                  className="accordian-item-body"
                  style={{ maxHeight: viewMore3 ? 300 : 0 }}
                >
                  <div className="accordian-item-body-content">
                    <div className="setrow">
                    
                      <div className="col-md-6">
                        {userStackedAtl === null ||userStackedAtl === "" ? null : (
                          <div className='my-coll-test'>
                           <div>
                           <h2 className="accord-content-second-title">
                              Staked
                            </h2>
                            <h2 className="accord-second-sub-title">
                              {userStackedAtl} H2ON
                            </h2>
                            <span style={{ color: "white", fontSize: "10px" }}>
                              {(userStackedAtl * tokenPrice).toFixed(3)} USD
                            </span>
                            <h2 className="accord-content-second-title">
                              Locked
                            </h2>
                            <h2 className="accord-second-sub-title">
                              {userLockedAlt} H2ON
                            </h2>
                            <br/>
                            <h2 className="accord-content-second-title">
                              Earned
                            </h2>
                            <h2 className="accord-second-sub-title">
                              {atlanticPend} H2ON
                            </h2>
                            <span style={{ color: "white", fontSize: "10px" }}>
                              {(atlanticPend * tokenPrice).toFixed(3)} USD
                            </span>
                            <br />
                            <button
                               class="btn btn-md btn-auto btn-grad-mod-sm no-change"
                              onClick={() => handleCompound("3")}
                            >
                              Compound 
                            </button>
                           </div>
                            
                          </div>
                        )}
                      </div>
                  
                      <div className="col-md-6">
                        <span className="accord-content-second-title">
                          <span className="span-blue"> Lock</span> Duration :
                        </span>
                        <h2 className="accord-second-sub-title">365 Days</h2>
    
                        <span className="accord-content-second-title">
                          <span className="span-blue"> Deposit</span> Fee :
                        </span>
                        <h2 className="accord-second-sub-title"> 0.00 %</h2>
    
                        <span className="accord-content-second-title">
                          <span className="span-blue"> Withdraw</span> Fee :
                        </span>
                        <h2 className="accord-second-sub-title">0.15 %</h2>
                        <div className="box-glow">
                          {/* <h2 className="accord-glow-title">START STAKING</h2> */}
                          {userStackedAtl === null || userStackedAtl === 0 || userStackedAtl ==""|| userStackedAtl =="0.0"|| userStackedAtl =="0.00"|| userStackedAtl =="0.000"|| userStackedAtl =="0.0000"|| userStackedAtl =="0.00000" ? (
                            <a
                              onClick={() => handleOpen("3")}
                              // value="0"
                              // onPress={handleOpen(value)}
                              class="btn btn-md btn-auto btn-grad-mod-sm no-change"
                            >
                              Stake
                            </a>
                          ) : (
                            <div style={{flexDirection:'row'}}>
                            <div  class="btn btn-md btn-auto btn-grad-mod-sm no-change">
                                      <MinusOutlined
                                style={{
                                  color: "white",
                                  
                                  fontSize: "20px",
                                }}
                                onClick={() => handleOpenPay("3")}
                              />
                            </div>
                           
                            <div  class="btn btn-md btn-auto btn-grad-mod-sm no-change">
                              <PlusOutlined
                                style={{
                                  color: "white",
                                  
                                  fontSize: "20px",
                                 
                                }}
                                onClick={() => handleOpen("3")}
                              />
                            </div>
                     
                          </div>
                          )}
                        </div>
                      </div>
                    
                    </div>
                  </div>
                </div>
              </div>
            </div>
                            {/* Pacific Pool */}
        <div className="ac-body" >
              <div className="accordian-item">
                <div className="accordian-item-header" onClick={()=>setViewMore4(!viewMore4)}>
                  <div className="setrow">
                    <div className="col-md-6">
                      <div className="row">
                      
                        <div className="afterimage">
                          <h2 className="accord-title">H2ON Pacific Pool</h2>
{/* <h2 className="accord-sub-title">Stake H2ON</h2> */}
                          <h2 className="accord-title-span-sm">Lock: 730 Days</h2>
                        </div>
                      </div>
                    </div>
                  
                    <div className="col-md-6">
                      <h2 className="accord-title-span">APR</h2>
                      <h2 className="accord-sub-title">{pacificApr}%</h2>
                    </div>
                    
                   
                    <div className="morebutton" >
                    <PlusOutlined
                        style={{
                          color: "white",
                         
                          fontSize: "20px",
                          marginTop:"10px"
                        }}
                        onClick={()=>setViewMore4(!viewMore4)}
                      />
                     
                      {/* <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="white" className="bi bi-plus-lg" viewBox="0 0 16 16" onClick={() => setViewMore4(!viewMore4)}>
                        <path fill-rule="evenodd" d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2Z"/>
                      </svg> */}
                    </div>
                  </div>
                </div>
                <div
                  className="accordian-item-body"
                  style={{ maxHeight: viewMore4 ? 300 : 0 }}
                >
                  <div className="accordian-item-body-content">
                    <div className="setrow">
                    
                      <div className="col-md-6">
                        {userLockedPac === null ||userLockedPac === "" ? null : (
                          <div className='my-coll-test'>
                           <div>
                           <h2 className="accord-content-second-title">
                              Staked
                            </h2>
                            <h2 className="accord-second-sub-title">
                              {userLockedPac} H2ON
                            </h2>
                            <span style={{ color: "white", fontSize: "10px" }}>
                              {(userLockedPac * tokenPrice).toFixed(3)} USD
                            </span>
                            <h2 className="accord-content-second-title">
                              Locked
                            </h2>
                            <h2 className="accord-second-sub-title">
                              {userLockedPac} H2ON
                            </h2>
                            <br/>
                            <h2 className="accord-content-second-title">
                              Earned
                            </h2>
                            <h2 className="accord-second-sub-title">
                              {pacificPend} H2ON
                            </h2>
                            <span style={{ color: "white", fontSize: "10px" }}>
                              {(pacificPend * tokenPrice).toFixed(3)} USD
                            </span>
                            <br />
                            <button
                              class="btn btn-md btn-auto btn-grad-mod-sm no-change"
                              onClick={() => handleCompound("4")}
                            >
                              Compound 
                            </button>
                           </div>
                            
                          </div>
                        )}
                      </div>
                     
                      <div className="col-md-6">
                        <span className="accord-content-second-title">
                          <span className="span-blue"> Lock</span> Duration :
                        </span>
                        <h2 className="accord-second-sub-title">730 Days</h2>
    
                        <span className="accord-content-second-title">
                          <span className="span-blue"> Deposit</span> Fee :
                        </span>
                        <h2 className="accord-second-sub-title"> 0.00 %</h2>
    
                        <span className="accord-content-second-title">
                          <span className="span-blue"> Withdraw</span> Fee :
                        </span>
                        <h2 className="accord-second-sub-title">0.15 %</h2>
                        <div className="box-glow">
                          {/* <h2 className="accord-glow-title">START STAKING</h2> */}
                          {userStackedPac === null || userStackedPac === 0 || userStackedPac ===""|| userStackedPac ==="0.0"|| userStackedPac ==="0.00"|| userStackedPac ==="0.000"|| userStackedPac ==="0.0000" ? (
                            <a
                              onClick={() => handleOpen("4")}
                              // value="0"
                              // onPress={handleOpen(value)}
                              class="btn btn-md btn-auto btn-grad-mod-sm no-change"
                            >
                              Stake
                            </a>
                          ) : (
                            <div style={{flexDirection:'row'}}>
                            <div  class="btn btn-md btn-auto btn-grad-mod-sm no-change">
                                      <MinusOutlined
                                style={{
                                  color: "white",
                                  
                                  fontSize: "20px",
                                }}
                                onClick={() => handleOpenPay("4")}
                              />
                            </div>
                           
                            <div  class="btn btn-md btn-auto btn-grad-mod-sm no-change">
                              <PlusOutlined
                                style={{
                                  color: "white",
                                  
                                  fontSize: "20px",
                                  
                         
                                }}
                                onClick={() => handleOpen("4")}
                              />
                            </div>
                     
                          </div>
                          )}
                        </div>
                      </div>
                    
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div style={{padding:"5%"}}>
            <img src={footer} style={{width:"40%"}}/>
            <div className='footer-div'>
                <div className='footer-icons'
                  onClick={() =>
                    window.open(
                      "https://twitter.com/H2O_Coin",
                      "_blank",
                    )
                  }
                >
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-twitter" viewBox="0 0 16 16">
                    <path d="M5.026 15c6.038 0 9.341-5.003 9.341-9.334 0-.14 0-.282-.006-.422A6.685 6.685 0 0 0 16 3.542a6.658 6.658 0 0 1-1.889.518 3.301 3.301 0 0 0 1.447-1.817 6.533 6.533 0 0 1-2.087.793A3.286 3.286 0 0 0 7.875 6.03a9.325 9.325 0 0 1-6.767-3.429 3.289 3.289 0 0 0 1.018 4.382A3.323 3.323 0 0 1 .64 6.575v.045a3.288 3.288 0 0 0 2.632 3.218 3.203 3.203 0 0 1-.865.115 3.23 3.23 0 0 1-.614-.057 3.283 3.283 0 0 0 3.067 2.277A6.588 6.588 0 0 1 .78 13.58a6.32 6.32 0 0 1-.78-.045A9.344 9.344 0 0 0 5.026 15z"/>
                  </svg>
                </div>
                <div className='footer-icons'
                 onClick={() =>
                  window.open(
                    "https://t.me/H2OSecuritiesForum",
                    "_blank",
                  )
                }
                >
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-telegram" viewBox="0 0 16 16">
                  <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.287 5.906c-.778.324-2.334.994-4.666 2.01-.378.15-.577.298-.595.442-.03.243.275.339.69.47l.175.055c.408.133.958.288 1.243.294.26.006.549-.1.868-.32 2.179-1.471 3.304-2.214 3.374-2.23.05-.012.12-.026.166.016.047.041.042.12.037.141-.03.129-1.227 1.241-1.846 1.817-.193.18-.33.307-.358.336a8.154 8.154 0 0 1-.188.186c-.38.366-.664.64.015 1.088.327.216.589.393.85.571.284.194.568.387.936.629.093.06.183.125.27.187.331.236.63.448.997.414.214-.02.435-.22.547-.82.265-1.417.786-4.486.906-5.751a1.426 1.426 0 0 0-.013-.315.337.337 0 0 0-.114-.217.526.526 0 0 0-.31-.093c-.3.005-.763.166-2.984 1.09z"/>
                </svg>
                </div>
                <div className='footer-icons'
                 onClick={() =>
                  window.open(
                    "https://www.linkedin.com/company/h2o-securities/",
                    "_blank",
                  )
                }
                >
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-linkedin" viewBox="0 0 16 16">
                    <path d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854V1.146zm4.943 12.248V6.169H2.542v7.225h2.401zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248-.822 0-1.359.54-1.359 1.248 0 .694.521 1.248 1.327 1.248h.016zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016a5.54 5.54 0 0 1 .016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225h2.4z"/>
                  </svg>
                </div>
                <div className='footer-icons'
                 onClick={() =>
                  window.open(
                    "https://medium.com/h2o-securities",
                    "_blank",
                  )
                }
                >
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-medium" viewBox="0 0 16 16">
                  <path d="M9.025 8c0 2.485-2.02 4.5-4.513 4.5A4.506 4.506 0 0 1 0 8c0-2.486 2.02-4.5 4.512-4.5A4.506 4.506 0 0 1 9.025 8zm4.95 0c0 2.34-1.01 4.236-2.256 4.236-1.246 0-2.256-1.897-2.256-4.236 0-2.34 1.01-4.236 2.256-4.236 1.246 0 2.256 1.897 2.256 4.236zM16 8c0 2.096-.355 3.795-.794 3.795-.438 0-.793-1.7-.793-3.795 0-2.096.355-3.795.794-3.795.438 0 .793 1.699.793 3.795z"/>
                </svg>
                </div>
                <div className='footer-icons'
                 onClick={() =>
                  window.open(
                    "https://www.facebook.com/H2OSecurities",
                    "_blank",
                  )
                }
                >
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-facebook" viewBox="0 0 16 16">
                <path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951z"/>
              </svg>
                </div>
                
            </div>
            <div>
              <p>Copyright © 2022 H2O Securities.</p>
              <p>All rights reserved.</p>
            </div>
          </div>
      </div>
     }
       </div>: null
    }
 
    </>
  );
}

export default App;
