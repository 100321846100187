import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { MoralisProvider } from "react-moralis";
// const APP_ID = process.env.REACT_APP_MORALIS_APPLICATION_ID;
// const SERVER_URL = process.env.REACT_APP_MORALIS_SERVER_URL;
ReactDOM.render(

  <React.StrictMode>
    <MoralisProvider appId='QRM9M9QCcWtIGlPFSvCaDThhJRpLGFkC4DDW76nD' serverUrl="https://dlicvhplz6hv.usemoralis.com:2053/server">
      <App />
    </MoralisProvider>
</React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
